import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";

export function Header() {
  const version = `${process.env.REACT_APP_FRONT_VERSION}`;

  return (
    <AppBar position="fixed">
      <Toolbar variant="dense">
        <Typography
          variant="h6"
          color="inherit"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          供給側接続事前検討申込
        </Typography>
        <Typography sx={{ mr: 2 }}>{version}</Typography>
      </Toolbar>
    </AppBar>
  );
}

import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

function notFound() {
  return (
    <React.Fragment>
      <div style={{ marginTop: "60px", marginLeft: "20px" }}>
        <Typography>お探しのページは見つかりませんでした。</Typography>
        <Link to={"/"}>トップへ戻る</Link>
      </div>
    </React.Fragment>
  );
}

export default notFound;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEntryForm = /* GraphQL */ `
  mutation CreateEntryForm(
    $input: CreateEntryFormInput!
    $condition: ModelEntryFormConditionInput
  ) {
    createEntryForm(input: $input, condition: $condition) {
      id
      mail
      form_data
      tmstmp
      createdAt
      updatedAt
    }
  }
`;
export const updateEntryForm = /* GraphQL */ `
  mutation UpdateEntryForm(
    $input: UpdateEntryFormInput!
    $condition: ModelEntryFormConditionInput
  ) {
    updateEntryForm(input: $input, condition: $condition) {
      id
      mail
      form_data
      tmstmp
      createdAt
      updatedAt
    }
  }
`;
export const deleteEntryForm = /* GraphQL */ `
  mutation DeleteEntryForm(
    $input: DeleteEntryFormInput!
    $condition: ModelEntryFormConditionInput
  ) {
    deleteEntryForm(input: $input, condition: $condition) {
      id
      mail
      form_data
      tmstmp
      createdAt
      updatedAt
    }
  }
`;
export const createUserMailCompany = /* GraphQL */ `
  mutation CreateUserMailCompany(
    $input: CreateUserMailCompanyInput!
    $condition: ModelUserMailCompanyConditionInput
  ) {
    createUserMailCompany(input: $input, condition: $condition) {
      mail
      tmstmp
      company_name
      postCd
      address
      tel
      personInChargeName
      mobilePhone
      createdAt
      updatedAt
    }
  }
`;
export const updateUserMailCompany = /* GraphQL */ `
  mutation UpdateUserMailCompany(
    $input: UpdateUserMailCompanyInput!
    $condition: ModelUserMailCompanyConditionInput
  ) {
    updateUserMailCompany(input: $input, condition: $condition) {
      mail
      tmstmp
      company_name
      postCd
      address
      tel
      personInChargeName
      mobilePhone
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserMailCompany = /* GraphQL */ `
  mutation DeleteUserMailCompany(
    $input: DeleteUserMailCompanyInput!
    $condition: ModelUserMailCompanyConditionInput
  ) {
    deleteUserMailCompany(input: $input, condition: $condition) {
      mail
      tmstmp
      company_name
      postCd
      address
      tel
      personInChargeName
      mobilePhone
      createdAt
      updatedAt
    }
  }
`;

import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Chip,
  Stack,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { setData, deleteData } from "../../Store/Slice/uploadFiles";
//FileDrop
import { useDropzone } from "react-dropzone";

//UUID
import { v4 as uuidv4 } from "uuid";

import dayjs from "dayjs";

const baseStyle = {
  width: "50%",
  height: 150,
  border: "1px dotted #888",
};
const borderNormalStyle = {
  border: "1px dotted #888",
};
const borderDragStyle = {
  border: "1px solid #00f",
  transition: "border .5s ease-in-out",
};

function FileUpload(props) {
  const [fileUploadCount, setFileUploadCount] = useState(0);
  const [fileUploadNo, setFileUploadNo] = useState(0);

  //初期表示時
  useEffect(() => {
    //console.log("init");
  }, []);

  //Storeから
  const dispatch = useDispatch();

  const keyUUID = useSelector((state) => state.company.key_uuid);
  const uploadedFileData = useSelector((state) => state.uploadFiles);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        console.log("fileRejections:", fileRejections);
        alert("アップロードできないファイルが含まれています。");
        return false;
      }
      if (fileUploadCount + acceptedFiles.length > 15) {
        alert("アップロード可能なファイル数の上限を超えます。");
        return false;
      }
      let maxFileSizeOver = false;
      acceptedFiles.forEach((file) => {
        if (file.size > 1024 * 1024 * 4.5) {
          maxFileSizeOver = true;
          return false;
        }
      });
      if (maxFileSizeOver) {
        alert(
          "アップロード可能なファイルサイズを超えたファイルが選択されています。"
        );
        return false;
      }

      let w_cnt = fileUploadNo;

      acceptedFiles.forEach((file) => {
        //setUpLoadFiles(...uploadFiles.push(file));
        const file_no = w_cnt++;
        props.setFile(file_no, file);
        dispatch(
          setData({
            no: file_no,
            data: {
              s3_key: keyUUID + "/" + uuidv4(),
              fileName: file.name,
              size: file.size,
              contentType: file.type,
              tmstmp: dayjs().format(),
            },
          })
        );
      });
      setFileUploadCount(fileUploadCount + acceptedFiles.length);
      setFileUploadNo(fileUploadNo + acceptedFiles.length);
    },
    [fileUploadCount, keyUUID, dispatch, props, fileUploadNo]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: {
      "image/*": [],
      "text/*": [],
      "application/pdf": [],
      "application/rtf": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      "application/vnd.ms-powerpoint": [],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [],
      "application/vnd.ms-excel": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/zip": [],
      "application/x-zip-compressed": [],
      //"application/*": [".docx", ".xlsx", ".doc"],
    },
    onDrop,
    noClick: true,
    maxFiles: 15,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? borderDragStyle : borderNormalStyle),
    }),
    [isDragActive]
  );

  const [deleteFileNo, setDeleteFileNo] = useState(-1);
  //let deleteFileNo = -1;

  function comfirmFileDelete(filename, fileNo) {
    setDeleteFileNo(fileNo);
    //deleteFileNo = fileNo;
    setConfirmInfo({
      title: "取消の確認",
      message: "「" + filename + "」のアップロードを取消ますか？",
    });
    setConfirmOpen(true);
  }

  //ファイルリスト
  const files = useMemo(
    () =>
      uploadedFileData
        .filter((file) => file.s3_key && file.s3_key.length > 0)
        .map((file, index) => (
          <ListItem key={file.s3_key}>
            <Chip
              label={file.fileName}
              onDelete={() => {
                comfirmFileDelete(file.fileName, file.s3_key);
              }}
            />
          </ListItem>
        )),
    [uploadedFileData]
  );

  //確認ダイアログ
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState({ title: "", message: "" });

  const handleClose = (e) => {
    setConfirmOpen(false);
  };
  const handleOk = (e) => {
    console.log(deleteFileNo);
    for (let i = 0; i < uploadedFileData.length; i++) {
      if (uploadedFileData[i].s3_key === deleteFileNo) {
        dispatch(deleteData({ no: i }));
        props.setFile(i, null);
      }
    }
    setFileUploadCount(fileUploadCount - 1);
    setConfirmOpen(false);
  };

  return (
    <Card sx={{ mt: 1 }}>
      <CardHeader title="添付ファイル"></CardHeader>
      <CardContent>
        <Typography sx={{ fontWeight: "bold" }}>
          添付ファイルがあればアップロードしてください。
          <br />
          アップロード可能なファイルは、.pdf, .docx, .xlsx .txt .zip です。
          <br />
          あと、
          {15 - fileUploadCount}ファイルまでアップロードできます。
        </Typography>
        <div className="container">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>
              ここにファイルをドロップするか、下のボタンを押してファイルを選択してください。
            </p>

            <Button
              variant="contained"
              size="small"
              color="success"
              sx={{ ml: 5 }}
              onClick={open}
            >
              ファイル選択
            </Button>
          </div>
          <aside>
            <h4>アップロードファイルリスト</h4>
            <Stack spacing={1}>{files}</Stack>
          </aside>
        </div>
        <Dialog
          open={confirmOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{confirmInfo.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {confirmInfo.message}
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="cancel" onClick={handleClose} autoFocus>
              キャンセル
            </Button>
            <Button id="ok" onClick={handleOk}>
              Ｏ　Ｋ
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
}

export default React.memo(FileUpload);

import { createSlice } from "@reduxjs/toolkit";
//state の初期値
const uploadFileInfo = {
  fileName: "",
  s3_key: "",
  size: 0,
  contentType: "",
  tmstmp: "",
  error: {
    fileName: false,
    s3_key: false,
    contentType: false,
  },
  message: {
    fileName: "",
    s3_key: "",
    contentType: "",
  },
};
const initialState = [uploadFileInfo];

//Sliceを生成する
const slice = createSlice({
  name: "uploadFiles",
  initialState,
  reducers: {
    setData: (state, action) => {
      //console.log(action);
      state[action.payload.no] = Object.assign(
        {},
        state[action.payload.no],
        action.payload.data
      );
      return state;
    },
    deleteData: (state, action) => {
      state[action.payload.no] = {};
      return state;
    },
    getDataCount: (state) => {
      return state.filter((file) => file.s3_key && file.s3_key.length > 0)
        .length;
    },
    setError: (state, action) => {
      state.error = Object.assign({}, state.error, action.payload);
      return state;
    },
    setMessage: (state, action) => {
      state.message = Object.assign({}, state.message, action.payload);
      return state;
    },
    clearData: () => {
      return initialState;
    },
  },
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const {
  setData,
  setError,
  setMessage,
  clearData,
  deleteData,
  getDataCount,
} = slice.actions;

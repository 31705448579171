import dayjs from "dayjs";

const loggerPrefix = "amplify-logger";
const appName = "supply-inpuiry";

export const logging = {
  logGroupName: `/${loggerPrefix}/${appName}/${process.env.REACT_APP_ENV}`,
  logStreamName: dayjs().format("YYYY/MM/DD"),
};

export const logLevel = "INFO";

export function getLogging(params) {
  const logging = {
    //logGroupName: `/${loggerPrefix}/${appName}/${process.env.NODE_ENV}`,
    logGroupName: `/${loggerPrefix}/${appName}/${process.env.REACT_APP_ENV}`,
    logStreamName: dayjs().format("YYYY/MM/DD") + "/" + params,
  };

  return logging;
}

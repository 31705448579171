import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  Collapse,
  Alert,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { setConsiderationData } from "../../Store/Slice/considerationItem";

function ConsiderationItem(props) {
  //初期表示時
  useEffect(() => {
    //console.log("init");
  }, []);

  //「外線工事要否のみ」以外のdisabled属性
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);
  //Storeから
  const dispatch = useDispatch();
  //Storeへ
  const changeValue = function (e) {
    //console.log(e.target);
    if (e.target.id === "consideration_01") {
      if (e.target.checked) {
        set_confirmOpen_consideration_01(true);
        return false;
      } else {
        setCheckboxDisabled(false);
      }
    }
    switch (e.target.id) {
      case "consideration_etc_text":
        dispatch(setConsiderationData({ [e.target.id]: e.target.value }));
        break;
      default:
        dispatch(setConsiderationData({ [e.target.id]: e.target.checked }));
    }
  };
  const considerationItem = useSelector((state) => state.considerationItem);
  //console.log("ConsiderationItem");
  //確認ダイアログ用
  const [confirmOpen_consideration_01, set_confirmOpen_consideration_01] =
    useState(false);
  function handleClose_consideration_01() {
    set_confirmOpen_consideration_01(false);
  }
  function confirmconsideration_01_OkAction() {
    setCheckboxDisabled(true);
    dispatch(setConsiderationData({ consideration_01: true }));
    dispatch(setConsiderationData({ consideration_02: false }));
    dispatch(setConsiderationData({ consideration_03: false }));
    dispatch(setConsiderationData({ consideration_04: false }));
    dispatch(setConsiderationData({ consideration_05: false }));
    dispatch(setConsiderationData({ consideration_06: false }));
    dispatch(setConsiderationData({ consideration_07: false }));
    dispatch(setConsiderationData({ consideration_08: false }));
    dispatch(setConsiderationData({ consideration_09: false }));
    dispatch(setConsiderationData({ consideration_10: false }));
    dispatch(setConsiderationData({ consideration_11: false }));
    dispatch(setConsiderationData({ consideration_12: false }));
    dispatch(setConsiderationData({ consideration_13: false }));
    dispatch(setConsiderationData({ consideration_etc: false }));
    dispatch(setConsiderationData({ consideration_etc_text: "" }));
    set_confirmOpen_consideration_01(false);
  }

  return (
    <React.Fragment>
      <div>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="４．お申込みを希望される検討項目（複数選択可）"></CardHeader>
          <Collapse in={considerationItem.error.allItems}>
            <Alert severity="error">{considerationItem.message.allItems}</Alert>
          </Collapse>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <FormControlLabel
                  label="外線工事要否のみ"
                  value="01"
                  control={
                    <Checkbox
                      id="consideration_01"
                      checked={useSelector(
                        (state) => state.considerationItem.consideration_01
                      )}
                      onChange={changeValue}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label="供給地点および供給方法"
                  value="02"
                  control={
                    <Checkbox
                      id="consideration_02"
                      checked={useSelector(
                        (state) => state.considerationItem.consideration_02
                      )}
                      onChange={changeValue}
                      disabled={checkboxDisabled}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label="工事期間"
                  value="03"
                  control={
                    <Checkbox
                      id="consideration_03"
                      checked={useSelector(
                        (state) => state.considerationItem.consideration_03
                      )}
                      onChange={changeValue}
                      disabled={checkboxDisabled}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <FormControlLabel
                  label="工事費負担金等の有無"
                  value="04"
                  control={
                    <Checkbox
                      id="consideration_04"
                      checked={useSelector(
                        (state) => state.considerationItem.consideration_04
                      )}
                      onChange={changeValue}
                      disabled={checkboxDisabled}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label="工事費負担金等概算額"
                  value="05"
                  control={
                    <Checkbox
                      id="consideration_05"
                      checked={useSelector(
                        (state) => state.considerationItem.consideration_05
                      )}
                      onChange={changeValue}
                      disabled={checkboxDisabled}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label="市街地開発事業に伴う検討"
                  value="06"
                  control={
                    <Checkbox
                      id="consideration_06"
                      checked={useSelector(
                        (state) => state.considerationItem.consideration_06
                      )}
                      onChange={changeValue}
                      disabled={checkboxDisabled}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <FormControlLabel
                  label="励磁突入対策要否（高圧）"
                  value="07"
                  control={
                    <Checkbox
                      id="consideration_07"
                      checked={useSelector(
                        (state) => state.considerationItem.consideration_07
                      )}
                      onChange={changeValue}
                      disabled={checkboxDisabled}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label="電力量計量器工事要否"
                  value="09"
                  control={
                    <Checkbox
                      id="consideration_09"
                      checked={useSelector(
                        (state) => state.considerationItem.consideration_09
                      )}
                      onChange={changeValue}
                      disabled={checkboxDisabled}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label="通信設備工事要否"
                  value="10"
                  control={
                    <Checkbox
                      id="consideration_10"
                      checked={useSelector(
                        (state) => state.considerationItem.consideration_10
                      )}
                      onChange={changeValue}
                      disabled={checkboxDisabled}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <FormControlLabel
                  label="特例需要場所適用可否"
                  value="11"
                  control={
                    <Checkbox
                      id="consideration_11"
                      checked={useSelector(
                        (state) => state.considerationItem.consideration_11
                      )}
                      onChange={changeValue}
                      disabled={checkboxDisabled}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label="電力融通適用可否"
                  value="12"
                  control={
                    <Checkbox
                      id="consideration_12"
                      checked={useSelector(
                        (state) => state.considerationItem.consideration_12
                      )}
                      onChange={changeValue}
                      disabled={checkboxDisabled}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={5}>
                <FormControlLabel
                  label="高圧ケーブル径種・ＣＢ遮断容量・Ｂ種接地抵抗値（計算書）"
                  value="13"
                  control={
                    <Checkbox
                      id="consideration_13"
                      checked={useSelector(
                        (state) => state.considerationItem.consideration_13
                      )}
                      onChange={changeValue}
                      disabled={checkboxDisabled}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <FormControlLabel
                  label="その他電気の供給に必要となる工事の要否"
                  value="etc"
                  control={
                    <Checkbox
                      id="consideration_etc"
                      checked={useSelector(
                        (state) => state.considerationItem.consideration_etc
                      )}
                      onChange={changeValue}
                      disabled={checkboxDisabled}
                    />
                  }
                />
              </Grid>
              <Grid item md={8}>
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  label="その他電気の供給に必要となる工事の要否"
                  variant="outlined"
                  value={useSelector(
                    (state) => state.considerationItem.consideration_etc_text
                  )}
                  id="consideration_etc_text"
                  onChange={changeValue}
                  error={useSelector(
                    (state) =>
                      state.considerationItem.error.consideration_etc_text
                  )}
                  helperText={useSelector(
                    (state) =>
                      state.considerationItem.message.consideration_etc_text
                  )}
                  disabled={checkboxDisabled}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      {/*外線工事要否のみチェック確認ダイアログ*/}
      <Dialog
        open={confirmOpen_consideration_01}
        onClose={handleClose_consideration_01}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">確認</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            「外線工事要否のみ」をチェックすると、他の検討項目のチェックが外れ
            <br />
            チェックできなくなりますが、よろしいですか？
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="btn_cancel" onClick={handleClose_consideration_01}>
            キャンセル
          </Button>
          <Button
            id="btn_ok"
            onClick={confirmconsideration_01_OkAction}
            autoFocus
          >
            ＯＫ
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default React.memo(ConsiderationItem);

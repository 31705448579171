/**
 * 希望契約内容
 */
import { createSlice } from "@reduxjs/toolkit";

//state の初期値
const entory_info = {
  entory_check: false,
  entory_contents: "",
  construction_type: "",
  responsibility_point: "",
  error: {
    entory_check: false,
    entory_contents: false,
    construction_type: false,
    responsibility_point: false,
  },
  message: {
    entory_check: "",
    entory_contents: "",
    construction_type: "",
    responsibility_point: "",
  },
};
const initialState = {
  low: { entory_info },
  high: { entory_info },
  sphigh: { entory_info },
};

//Sliceを生成する
const slice = createSlice({
  name: "desiredContractVlt",
  initialState,
  reducers: {
    setData: (state, action) => {
      //console.log(action);
      state[action.payload.type].entory_info = Object.assign(
        {},
        state[action.payload.type].entory_info,
        action.payload.data
      );
      return state;
    },
    setError: (state, action) => {
      state.error = Object.assign({}, state.error, action.payload);
      return state;
    },
    setMessage: (state, action) => {
      state.message = Object.assign({}, state.message, action.payload);
      return state;
    },
    clearData: () => {
      return initialState;
    },
    validate: (state) => {
      if (
        state.low.entory_info.entory_check === true &&
        state.low.entory_info.entory_contents.length === 0
      ) {
        state.low.entory_info.error = Object.assign(
          {},
          state.low.entory_info.error,
          {
            entory_contents: true,
          }
        );
        state.low.entory_info.message = Object.assign(
          {},
          state.low.entory_info.message,
          {
            entory_contents: "申込内容を選択してください。",
          }
        );
      } else {
        state.low.entory_info.error = Object.assign(
          {},
          state.low.entory_info.error,
          {
            entory_contents: false,
          }
        );
        state.low.entory_info.message = Object.assign(
          {},
          state.low.entory_info.message,
          {
            entory_contents: "",
          }
        );
      }
      if (
        state.high.entory_info.entory_check === true &&
        state.high.entory_info.entory_contents.length === 0
      ) {
        state.high.entory_info.error = Object.assign(
          {},
          state.high.entory_info.error,
          {
            entory_contents: true,
          }
        );
        state.high.entory_info.message = Object.assign(
          {},
          state.high.entory_info.message,
          {
            entory_contents: "申込内容を選択してください。",
          }
        );
      } else {
        state.high.entory_info.error = Object.assign(
          {},
          state.high.entory_info.error,
          {
            entory_contents: false,
          }
        );
        state.high.entory_info.message = Object.assign(
          {},
          state.high.entory_info.message,
          {
            entory_contents: "",
          }
        );
      }
      return state;
    },
  },
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const { setData, setError, setMessage, clearData, validate } =
  slice.actions;

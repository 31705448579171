import {createSlice} from "@reduxjs/toolkit";

//state の初期値
const specialDeviceInfo = {
    special_device_name :"",
    special_sou :"",
    special_vlt : "",
    special_capacity : "",
    special_pre_cnt : "",
    special_after_cnt : "",
    special_total_capacity : "",
    isEdit : false,
    error : {
        special_device_name :false,
        special_sou :false,
        special_vlt : false,
        special_capacity : false,
        special_pre_cnt : false,
        special_after_cnt : false,
        special_total_capacity: false,
    },
    message : {
        special_device_name :"",
        special_sou :"",
        special_vlt : "",
        special_capacity : "",
        special_pre_cnt : "",
        special_after_cnt : "",
        special_total_capacity : "",
    }
}
const initialState = 
    [specialDeviceInfo, specialDeviceInfo, specialDeviceInfo]


//Sliceを生成する
const slice = createSlice({
    name : "specialDevice"
    ,initialState
    ,reducers : {
        setData :(state, action) => {
            //console.log(action);
            state[action.payload.no] = Object.assign({}, state[action.payload.no], action.payload.data);
            return state;
        }
        , setError:(state, action) => {
            state.error =  Object.assign({}, state.error, action.payload);
            return state;
        }
        , setMessage:(state, action) => {
            state.message =  Object.assign({}, state.message, action.payload);
            return state;
        }
        , clearData :() => {
            return initialState;
        }
    }
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const {setData, setError, setMessage, clearData} = slice.actions;

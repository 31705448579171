import {createSlice} from "@reduxjs/toolkit";

//state の初期値
const snowMeltInfo = {
    snow_melt_supply_vlt :"",
    snow_melt_capacity :"",
    snow_melt_cnt : "",
    isEdit : false,
    error : {
        snow_melt_supply_vlt :false,
        snow_melt_capacity : false,
        snow_melt_cnt : false,
    },
    message : {
        snow_melt_supply_vlt :"",
        snow_melt_capacity : "",
        snow_melt_cnt : "",
    }
}
const initialState = 
    [snowMeltInfo,snowMeltInfo,snowMeltInfo]


//Sliceを生成する
const slice = createSlice({
    name : "lowSnowMelt"
    ,initialState
    ,reducers : {
        setData :(state, action) => {
            //console.log(action);
            state[action.payload.no] = Object.assign({}, state[action.payload.no], action.payload.data);
            return state;
        }
        , setError:(state, action) => {
            state.error =  Object.assign({}, state.error, action.payload);
            return state;
        }
        , setMessage:(state, action) => {
            state.message =  Object.assign({}, state.message, action.payload);
            return state;
        }
        , clearData :() => {
            return initialState;
        }

    }
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const {setData, setError, setMessage, clearData} = slice.actions;

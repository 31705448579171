import {createSlice} from "@reduxjs/toolkit";

//state の初期値
const lowLightInfo = {
    lamp_supply_vlt :"",
    lamp_va :"",
    lamp_a : "",
    lamp_kva : "",
    lamp_cnt : "",
    isEdit : false,
    error : {
        lamp_supply_vlt :false,
        lamp_va :false,
        lamp_a : false,
        lamp_kva : false,
        lamp_cnt: false,
    },
    message : {
        lamp_supply_vlt :"",
        lamp_va :"",
        lamp_a : "",
        lamp_kva : "",
        lamp_cnt : "",
    }
}
const initialState = 
    [lowLightInfo,lowLightInfo,lowLightInfo,lowLightInfo,lowLightInfo,lowLightInfo]


//Sliceを生成する
const slice = createSlice({
    name : "lowLight"
    ,initialState
    ,reducers : {
        setData :(state, action) => {
            //console.log(action);
            state[action.payload.no] = Object.assign({}, state[action.payload.no], action.payload.data);
            return state;
        }
        , setError:(state, action) => {
            state.error =  Object.assign({}, state.error, action.payload);
            return state;
        }
        , setMessage:(state, action) => {
            state.message =  Object.assign({}, state.message, action.payload);
            return state;
        }
        , clearData :() => {
            return initialState;
        }
    }
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const {setData, setError, setMessage, clearData} = slice.actions;

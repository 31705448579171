import { createSlice } from "@reduxjs/toolkit";

//state の初期値
const initialState = {
  special_device: "",
  keitou: "",
  error: {
    special_device: false,
    keitou: false,
  },
  message: {
    special_device: "",
    keitou: "",
  },
};

//Sliceを生成する
const slice = createSlice({
  name: "desiredContractInfo",
  initialState,
  reducers: {
    setData: (state, action) => {
      //console.log(action);
      return Object.assign({}, state, action.payload);
    },
    setError: (state, action) => {
      state.error = Object.assign({}, state.error, action.payload);
      return state;
    },
    setMessage: (state, action) => {
      state.message = Object.assign({}, state.message, action.payload);
      return state;
    },
    clearData: () => {
      return initialState;
    },
  },
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const { setData, setError, setMessage, clearData } = slice.actions;

import React, { useEffect } from "react";
import {
  Grid,
  MenuItem,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../../../Store/Slice/lowLight";
import { replaceNumStr } from "../../../components/util";

function LowLight(props) {
  const isEditeOk = useSelector(
    (state) => state.lowLight[props.lineNo - 1].isEdit
  );

  //初期表示時
  useEffect(() => {
    //先頭行だけ入力可能とする
    if (props.lineNo === 1) {
      dispatch(setData({ no: props.lineNo - 1, data: { isEdit: true } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Storeから
  const dispatch = useDispatch();
  //Storeへ
  const changeValue = function (e) {
    //console.log(target);
    switch (e.target.id) {
      case "lowDelegation":
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: e.target.checked },
          })
        );
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: e.target.value },
          })
        );
    }
  };
  const changeSelect = function (e) {
    //console.log(e);
    switch (e.target.name) {
      case "lowDelegation":
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.name]: e.target.checked },
          })
        );
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.name]: e.target.value },
          })
        );
    }
  };

  const blurField = function (e) {
    //console.log(target);
    switch (e.target.id) {
      case "lamp_va":
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: replaceNumStr(e.target.value) },
          })
        );
        break;
      case "lamp_cnt":
        if (props.lineNo < 6) {
          if (e.target.value.length > 0) {
            //口数が入力されていたら、
            dispatch(setData({ no: props.lineNo, data: { isEdit: true } }));
            dispatch(
              setData({
                no: props.lineNo - 1,
                data: { [e.target.id]: replaceNumStr(e.target.value) },
              })
            );
          } else {
            dispatch(setData({ no: props.lineNo, data: { isEdit: false } }));
          }
        }
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: replaceNumStr(e.target.value) },
          })
        );
    }
  };

  //console.log("LowLight");
  return (
    <Grid container spacing={2} sx={{ m: 2 }}>
      <span style={{ marginTop: "1.5em" }}>「{props.lineNo}」</span>
      <Grid item md={2}>
        <FormControl fullWidth size="small" disabled={!isEditeOk}>
          <InputLabel>供給電気方式・供給電圧</InputLabel>
          <Select
            label="供給電気方式・供給電圧"
            id="lamp_supply_vlt"
            name="lamp_supply_vlt"
            onChange={changeSelect}
            value={useSelector(
              (state) => state.lowLight[props.lineNo - 1].lamp_supply_vlt
            )}
          >
            <MenuItem key={0} value=""></MenuItem>
            <MenuItem key={1} value="単相2線式100V">
              単相2線式100V
            </MenuItem>
            <MenuItem key={2} value="単相2線式200V">
              単相2線式200V
            </MenuItem>
            <MenuItem key={3} value="単相3線式100V">
              単相3線式100V
            </MenuItem>
            <MenuItem key={4} value="三相3線式200V">
              三相3線式200V
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2}>
        <TextField
          fullWidth
          type="number"
          size="small"
          label="定額電灯"
          variant="outlined"
          required={false}
          disabled={!isEditeOk}
          InputProps={{
            endAdornment: <InputAdornment position="end">VA</InputAdornment>,
            inputProps: { min: 1 },
          }}
          id="lamp_va"
          value={useSelector(
            (state) => state.lowLight[props.lineNo - 1].lamp_va
          )}
          onChange={changeValue}
          onBlur={blurField}
        />
      </Grid>
      <Grid item md={2}>
        <TextField
          fullWidth
          type="number"
          size="small"
          label="契約電流"
          variant="outlined"
          required={false}
          disabled={!isEditeOk}
          InputProps={{
            endAdornment: <InputAdornment position="end">A</InputAdornment>,
            inputProps: { min: 1 },
          }}
          id="lamp_a"
          value={useSelector(
            (state) => state.lowLight[props.lineNo - 1].lamp_a
          )}
          onChange={changeValue}
          onBlur={blurField}
        />
      </Grid>
      <Grid item md={2}>
        <TextField
          fullWidth
          type="number"
          size="small"
          label="契約容量"
          variant="outlined"
          required={false}
          disabled={!isEditeOk}
          InputProps={{
            endAdornment: <InputAdornment position="end">kVA</InputAdornment>,
            inputProps: { min: 1 },
          }}
          id="lamp_kva"
          value={useSelector(
            (state) => state.lowLight[props.lineNo - 1].lamp_kva
          )}
          onChange={changeValue}
          onBlur={blurField}
        />
      </Grid>
      <Grid item md={1}>
        <TextField
          fullWidth
          type="number"
          size="small"
          label="口数"
          variant="outlined"
          required={false}
          disabled={!isEditeOk}
          InputProps={{
            endAdornment: <InputAdornment position="end">口</InputAdornment>,
            inputProps: { min: 1 },
          }}
          id="lamp_cnt"
          value={useSelector(
            (state) => state.lowLight[props.lineNo - 1].lamp_cnt
          )}
          onChange={changeValue}
          onBlur={blurField}
        />
      </Grid>
    </Grid>
  );
}

export default React.memo(LowLight);

import { createSlice } from "@reduxjs/toolkit";

//state の初期値
const initialState = {
  consideration_01: false,
  consideration_02: false,
  consideration_03: false,
  consideration_04: false,
  consideration_05: false,
  consideration_06: false,
  consideration_07: false,
  consideration_08: false,
  consideration_09: false,
  consideration_10: false,
  consideration_11: false,
  consideration_12: false,
  consideration_13: false,
  consideration_etc: false,
  consideration_etc_text: "",
  error: {
    allItems: false,
    consideration_01: false,
    consideration_02: false,
    consideration_03: false,
    consideration_04: false,
    consideration_05: false,
    consideration_06: false,
    consideration_07: false,
    consideration_08: false,
    consideration_09: false,
    consideration_10: false,
    consideration_11: false,
    consideration_12: false,
    consideration_13: false,
    consideration_etc: false,
    consideration_etc_text: false,
  },
  message: {
    allItems: "",
    consideration_01: "",
    consideration_02: "",
    consideration_03: "",
    consideration_04: "",
    consideration_05: "",
    consideration_06: "",
    consideration_07: "",
    consideration_08: "",
    consideration_09: "",
    consideration_10: "",
    consideration_11: "",
    consideration_12: "",
    consideration_13: "",
    consideration_etc: "",
    consideration_etc_text: "",
  },
};

//Sliceを生成する
const slice = createSlice({
  name: "considerationItem",
  initialState,
  reducers: {
    setConsiderationData: (state, action) => {
      return Object.assign({}, state, action.payload);
    },
    clearData: () => {
      return initialState;
    },
    validate: (state) => {
      if (
        state.consideration_01 === false &&
        state.consideration_02 === false &&
        state.consideration_03 === false &&
        state.consideration_04 === false &&
        state.consideration_05 === false &&
        state.consideration_06 === false &&
        state.consideration_07 === false &&
        state.consideration_08 === false &&
        state.consideration_09 === false &&
        state.consideration_10 === false &&
        state.consideration_11 === false &&
        state.consideration_12 === false &&
        state.consideration_13 === false &&
        state.consideration_etc === false
      ) {
        state.error = Object.assign({}, state.error, { allItems: true });
        state.message = Object.assign({}, state.message, {
          allItems: "検討項目を選択してください。",
        });
      } else {
        state.error = Object.assign({}, state.error, { allItems: false });
        state.message = Object.assign({}, state.message, {
          allItems: "",
        });
      }
      if (
        state.consideration_etc === true &&
        state.consideration_etc_text.length === 0
      ) {
        state.error = Object.assign({}, state.error, {
          consideration_etc_text: true,
        });
        state.message = Object.assign({}, state.message, {
          consideration_etc_text:
            "その他を選択した場合は、その他電気の供給に必要となる工事の要否を入力してください。",
        });
      } else {
        state.error = Object.assign({}, state.error, {
          consideration_etc_text: false,
        });
        state.message = Object.assign({}, state.message, {
          consideration_etc_text: "",
        });
      }
      return state;
    },
  },
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const { setConsiderationData, clearData, validate } = slice.actions;

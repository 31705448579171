import {createSlice} from "@reduxjs/toolkit";

//state の初期値
const highReserveInfo = {
    sphigh_reserve_service :"",
    sphigh_reserve_contract_ep :"",
    sphigh_reserve_supply : "",
    sphigh_reserve_measure : "",
    isEdit : false,
    error : {
        sphigh_reserve_service :false,
        sphigh_reserve_contract_ep :false,
        sphigh_reserve_supply : false,
        sphigh_reserve_measure : false,
    },
    message : {
        sphigh_reserve_service :"",
        sphigh_reserve_contract_ep :"",
        sphigh_reserve_supply : "",
        sphigh_reserve_measure : "",
    }
}
const initialState = 
    [highReserveInfo]


//Sliceを生成する
const slice = createSlice({
    name : "exhighReserve"
    ,initialState
    ,reducers : {
        setData :(state, action) => {
            //console.log(action);
            state[action.payload.no] = Object.assign({}, state[action.payload.no], action.payload.data);
            return state;
        }
        , setError:(state, action) => {
            state.error =  Object.assign({}, state.error, action.payload);
            return state;
        }
        , setMessage:(state, action) => {
            state.message =  Object.assign({}, state.message, action.payload);
            return state;
        }
        , clearData :() => {
            return initialState;
        }
    }
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const {setData, setError, setMessage, clearData} = slice.actions;

import React from "react";
import { Flex, Text, useTheme } from "@aws-amplify/ui-react";
import { Fragment } from "react";
import { Typography } from "@mui/material";

export function Footer() {
  const { tokens } = useTheme();
  const build_date = `${process.env.REACT_APP_BUILD_DATE}`;

  return (
    <Fragment>
      <Typography
        align="center"
        variant="body2"
        sx={{ mt: "30px", width: "55em", ml: "-130px" }}
      >
        北海道電力ネットワーク株式会社All Rights Reserved. Copyright © Hokkaido
        Electric Power Network, Inc.
      </Typography>
      <Flex justifyContent="center" padding={tokens.space.medium}>
        <Text>{build_date}</Text>
      </Flex>
    </Fragment>
  );
}

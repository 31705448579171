import React from "react";
import "./App.css";

import { Amplify, Logger, AWSCloudWatchProvider } from "aws-amplify";

import { withAuthenticator } from "@aws-amplify/ui-react";
import { Typography, AppBar, Toolbar } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";

import ScrollToTop from "./components/ScrollToTop";

import { Header } from "./Auth/Header";
import { Footer } from "./Auth/Footer";
import ApplicationEntrance from "./pages/ApplicationEntrance";
import EntryForm from "./pages/EntryForm";
import NotFound from "./pages/NotFound";
import Thanks from "./pages/Thanks";
import Error from "./pages/Error";

import "@aws-amplify/ui-react/styles.css";

//UUID
import { v4 as uuidv4 } from "uuid";

import awsExports from "./aws-exports";
import { logLevel, getLogging } from "./LogConst";
Amplify.configure({
  Logging: getLogging(uuidv4()),
  ...awsExports,
});
//ログ
const logger = new Logger("LOG", logLevel);
Amplify.register(logger);
logger.addPluggable(new AWSCloudWatchProvider());

I18n.putVocabularies(translations);
I18n.putVocabulariesForLanguage("ja", {
  "User does not exist.": "メールアドレスまたはパスワードが違います",
  "Incorrect username or password.": "メールアドレスまたはパスワードが違います",
  "An account with the given email already exists.":
    "メールアドレスはすでに登録済みです。",
  "Your passwords must match": "確認パスワードが不一致です",
  "Password must have at least 8 characters":
    "パスワードは8文字以上を入力してください (8文字以上の英数字)",
  "Password must have at least 10 characters":
    "パスワードは10文字以上を入力してください",
  "Password must have at least 14 characters":
    "パスワードは14文字以上を入力してください",
  "Password must have numbers": "パスワードには数字含めてください",
  "Password must have upper case letters":
    "パスワードには大文字英字含めてください",
  "Password must have lower case letters":
    "パスワードには小文字英字を含めてください",
  "Password must have special characters": "パスワードには記号を含めてください",
});
I18n.setLanguage("ja");

function App({ signOut, user }) {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            供給側接続事前検討申込
          </Typography>
          <Typography sx={{ mr: 2 }}>{user.attributes.email}</Typography>
        </Toolbar>
      </AppBar>
      <Routes>
        <Route
          path={"/"}
          element={
            <ApplicationEntrance
              signOut={signOut}
              user={user}
              logger={logger}
            />
          }
        />
        <Route
          path={"/entory"}
          element={<EntryForm user={user} logger={logger} />}
        />
        <Route
          path={"/thanks"}
          element={<Thanks signOut={signOut} user={user} logger={logger} />}
        />
        <Route
          path={"/error"}
          element={<Error user={user} logger={logger} />}
        />
        <Route path={"/*"} element={<NotFound />} />
      </Routes>
      <Typography
        align="center"
        variant="body2"
        sx={{ mt: "30px", mb: "20px" }}
      >
        北海道電力ネットワーク株式会社All Rights Reserved. Copyright © Hokkaido
        Electric Power Network, Inc.
      </Typography>
    </BrowserRouter>
  );
}

export default withAuthenticator(App, {
  components: {
    Header,
    Footer,
  },
});

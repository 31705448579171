import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

function errorPage() {
  return (
    <React.Fragment>
      <div style={{ marginTop: "60px", marginLeft: "20px" }}>
        <Typography>申し訳ございません。エラーが発生しました。</Typography>
        <Typography>
          お手数ですが、トップへ戻り最初からやり直してください。
        </Typography>
        <Link to={"/"}>トップへ戻る</Link>
      </div>
    </React.Fragment>
  );
}

export default errorPage;

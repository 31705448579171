import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

//state の初期値
const initialState = {
  demand_name: "",
  demand_address: "",
  offer_area: "",
  supply_preferred_date: "",
  pre_study_no: "",
  hkkm_chu_no: "",
  supply_no_1: "",
  supply_no_2: "",
  supply_no_3: "",
  error: {
    demand_name: false,
    demand_address: false,
    offer_area: false,
    supply_preferred_date: false,
    pre_study_no: false,
    hkkm_chu_no: false,
    supply_no_1: false,
    supply_no_2: false,
    supply_no_3: false,
  },
  message: {
    demand_name: "",
    demand_address: "",
    offer_area: "",
    supply_preferred_date: "",
    pre_study_no: "",
    hkkm_chu_no: "",
    supply_no_1: "",
    supply_no_2: "",
    supply_no_3: "",
  },
};

//Sliceを生成する
const slice = createSlice({
  name: "preConsiderPointInfo",
  initialState,
  reducers: {
    setData: (state, action) => {
      return Object.assign({}, state, action.payload);
    },
    setError: (state, action) => {
      state.error = Object.assign({}, state.error, action.payload);
      return state;
    },
    setMessage: (state, action) => {
      state.message = Object.assign({}, state.message, action.payload);
      return state;
    },
    clearData: () => {
      return initialState;
    },
    validate: (state) => {
      if (state.demand_name.length === 0) {
        state.error = Object.assign({}, state.error, { demand_name: true });
        state.message = Object.assign({}, state.message, {
          demand_name: "需要者名を入力してください",
        });
      }
      if (state.demand_address.length === 0) {
        state.error = Object.assign({}, state.error, { demand_address: true });
        state.message = Object.assign({}, state.message, {
          demand_address: "需要場所を入力してください",
        });
      }
      if (state.supply_preferred_date.length === 0) {
        state.error = Object.assign({}, state.error, {
          supply_preferred_date: true,
        });
        state.message = Object.assign({}, state.message, {
          supply_preferred_date: "接続供給開始希望日を入力してください",
        });
      } else {
        //開始希望日＜今日はエラー
        const now = dayjs();
        const supply_preferred_date = dayjs(state.supply_preferred_date);
        //console.log(supply_preferred_date);
        if (supply_preferred_date.isBefore(now, "day")) {
          state.error = Object.assign({}, state.error, {
            supply_preferred_date: true,
          });
          state.message = Object.assign({}, state.message, {
            supply_preferred_date: "本日よりも後の日付を入力してください",
          });
        }
      }
      if (state.hkkm_chu_no.length === 0) {
        state.error = Object.assign({}, state.error, {
          hkkm_chu_no: true,
        });
        state.message = Object.assign({}, state.message, {
          hkkm_chu_no: "引込柱No.を入力してください",
        });
      }

      state.error = Object.assign({}, state.error, {
        supply_no_1: false,
      });
      state.message = Object.assign({}, state.message, {
        supply_no_1: "",
      });

      return state;
    },
  },
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const { setData, setError, setMessage, clearData, validate } =
  slice.actions;

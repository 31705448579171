import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  setData,
  setError,
  setMessage,
} from "../../Store/Slice/preConsiderPointInfoSlice";
import { store } from "../../Store/store";

import { jig_list } from "./EntryFormConst";

function PreConsiderPointInfo(props) {
  //Storeから
  const dispatch = useDispatch();
  //Storeへ
  const changeValue = function (target) {
    //console.log(target);
    switch (target.id) {
      case "lowDelegation":
        dispatch(setData({ [target.id]: target.checked }));
        break;
      default:
        dispatch(setData({ [target.id]: target.value }));
    }
  };
  const changeSelect = function (e) {
    //console.log(e);
    switch (e.target.name) {
      case "lowDelegation":
        dispatch(setData({ [e.target.id]: e.target.checked }));
        break;
      default:
        dispatch(setData({ [e.target.name]: e.target.value }));
    }
  };
  //カーソルが離れた
  const blurField = function (target) {
    //console.log(target);
    switch (target.name) {
      case "offer_area":
        setErrorMessage(
          target.name,
          checkEntry(target, "お申込みエリアを選択してください。")
        );
        break;
      default:
    }
    switch (target.id) {
      case "demand_name":
        setErrorMessage(
          target.id,
          checkEntry(target, "需要者名を入力してください。")
        );
        break;
      case "demand_address":
        setErrorMessage(
          target.id,
          checkEntry(target, "需要場所を入力してください。")
        );
        break;
      case "supply_preferred_date":
        setErrorMessage(
          target.id,
          checkEntry(target, "接続供給開始希望日を入力してください。")
        );
        break;
      case "hkkm_chu_no":
        checkPoleNo(target);
        break;
      case "supply_no_1":
      case "supply_no_2":
      case "supply_no_3":
        checkNum(target, 22, "供給地点番号");
        break;
      default:
      //dispatch(setData({[target.id]: target.value}));
    }
  };
  //必須入力チェック
  function checkEntry(target, err_msg) {
    if (target.value.length === 0) {
      return err_msg;
    }
    return "";
  }
  //引込柱チェック
  function checkPoleNo(target) {
    if (target.value.length === 0) {
      setErrorMessage(
        target.id,
        checkEntry(target, "引込柱No.を入力してください。")
      );
      return target.value;
    }
    const regex = /^\d{12}$/;
    if (regex.test(target.value) === false) {
      setErrorMessage(target.id, "引込柱は半角数字１２桁で入力してください。");
      return target.value;
    }
    //エラーなし
    setErrorMessage(target.id, "");
    return target.value;
  }
  //数字桁固定
  function checkNum(target, len, kmkName) {
    if (target.value.length === 0) {
      setErrorMessage(target.id, "");
      return target.value;
    }

    const regex = new RegExp("^\\d{" + len + "}$");
    if (regex.test(target.value) === false) {
      setErrorMessage(
        target.id,
        kmkName + "は半角数字" + len + "桁で入力してください。"
      );
      return target.value;
    }
    //エラーなし
    setErrorMessage(target.id, "");
    return target.value;
  }
  //メッセージセット
  function setErrorMessage(id, message) {
    dispatch(setError({ [id]: message.length > 0 }));
    dispatch(setMessage({ [id]: message }));
  }

  //お申込みエリア表示非表示（小売電気事業者以外）
  const [offer_area_dsp, setoffer_area_dsp] = useState(false);

  //初期表示時
  useEffect(() => {
    const state = store.getState();
    if (state.company.company_type === "kouji") {
      setoffer_area_dsp(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //console.log("PreConsiderPointInfo");
  return (
    <Card sx={{ mt: 1 }}>
      <CardHeader title="２．事前検討地点情報"></CardHeader>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <TextField
              fullWidth
              type="text"
              size="small"
              label="需要者名"
              variant="outlined"
              required={true}
              id="demand_name"
              value={useSelector(
                (state) => state.preConsiderPointInfo.demand_name
              )}
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector(
                (state) => state.preConsiderPointInfo.error.demand_name
              )}
              helperText={useSelector(
                (state) => state.preConsiderPointInfo.message.demand_name
              )}
            />
          </Grid>
          <Grid item>
            <Typography sx={{ pt: 2, fontSize: 11 }}>
              ＊企業の場合は企業名および代表者名を入力してください。
            </Typography>
          </Grid>
        </Grid>
        <div style={{ display: offer_area_dsp ? "block" : "none" }}>
          <Grid container spacing={2} mt={0}>
            <Grid item md={4}>
              <FormControl
                fullWidth
                size="small"
                disabled={false}
                value={""}
                error={useSelector(
                  (state) => state.preConsiderPointInfo.error.offer_area
                )}
              >
                <InputLabel required={true}>お申込みエリア</InputLabel>
                <Select
                  label="お申込みエリア"
                  id="offer_area"
                  name="offer_area"
                  onChange={changeSelect}
                  value={useSelector(
                    (state) => state.preConsiderPointInfo.offer_area
                  )}
                  onBlur={(e) => {
                    blurField(e.target);
                  }}
                >
                  <MenuItem key={0} value=""></MenuItem>
                  {jig_list.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>
                  {useSelector(
                    (state) => state.preConsiderPointInfo.message.offer_area
                  )}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography sx={{ pt: 2, fontSize: 11 }}>
                ＊お申込みエリアがご不明な場合は、「
                <a
                  href="https://www.hepco.co.jp/network/corporate/company/branch/search/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  お近くのほくでんネットワーク検索
                </a>
                」よりご確認ください。
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} mt={0}>
          <Grid item md={8}>
            <TextField
              fullWidth
              type="text"
              size="small"
              label="需要場所"
              variant="outlined"
              required={true}
              id="demand_address"
              value={useSelector(
                (state) => state.preConsiderPointInfo.demand_address
              )}
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector(
                (state) => state.preConsiderPointInfo.error.demand_address
              )}
              helperText={useSelector(
                (state) => state.preConsiderPointInfo.message.demand_address
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={0}>
          <Grid item md={2}>
            <TextField
              fullWidth
              type="date"
              size="small"
              label="接続供給開始希望日"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              required={true}
              id="supply_preferred_date"
              inputProps={{ min: "2022-09-24", max: "2999-12-31" }}
              value={useSelector(
                (state) => state.preConsiderPointInfo.supply_preferred_date
              )}
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector(
                (state) =>
                  state.preConsiderPointInfo.error.supply_preferred_date
              )}
              helperText={useSelector(
                (state) =>
                  state.preConsiderPointInfo.message.supply_preferred_date
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={0}>
          <Grid item md={2}>
            <TextField
              fullWidth
              type="text"
              size="small"
              label="事前検討No."
              variant="outlined"
              required={false}
              id="pre_study_no"
              value={useSelector(
                (state) => state.preConsiderPointInfo.pre_study_no
              )}
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector(
                (state) => state.preConsiderPointInfo.error.pre_study_no
              )}
              helperText={useSelector(
                (state) => state.preConsiderPointInfo.message.pre_study_no
              )}
            />
          </Grid>
          <Grid item>
            <Typography sx={{ pt: 2, fontSize: 11 }}>
              ＊過去に同地点で事前検討申込をされている場合、当時の回答書右上に記載のNo.を入力してください。
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={0}>
          <Grid item md={2}>
            <TextField
              fullWidth
              type="text"
              size="small"
              label="引込柱No."
              variant="outlined"
              required={true}
              id="hkkm_chu_no"
              value={useSelector(
                (state) => state.preConsiderPointInfo.hkkm_chu_no
              )}
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector(
                (state) => state.preConsiderPointInfo.error.hkkm_chu_no
              )}
              helperText={useSelector(
                (state) => state.preConsiderPointInfo.message.hkkm_chu_no
              )}
            />
          </Grid>
          <Grid item>
            <Typography sx={{ pt: 2, fontSize: 11 }}>
              ＊最寄りの弊社柱に設置されている札記載の12桁の数字を入力してください（数字のみ連続して入力）。
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={0}>
          <Grid item md={3}>
            <TextField
              fullWidth
              type="text"
              size="small"
              label="供給地点特定番号（１）"
              variant="outlined"
              required={false}
              id="supply_no_1"
              value={useSelector(
                (state) => state.preConsiderPointInfo.supply_no_1
              )}
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector(
                (state) => state.preConsiderPointInfo.error.supply_no_1
              )}
              helperText={useSelector(
                (state) => state.preConsiderPointInfo.message.supply_no_1
              )}
            />
          </Grid>
          <Grid item>
            <Typography sx={{ pt: 2, fontSize: 11 }}>
              ＊新設の場合は入力不要です。
              <span style={{ textDecoration: "underline" }}>
                設備変更の場合のみ入力してください（数字のみ連続して入力）
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={0}>
          <Grid item md={3}>
            <TextField
              fullWidth
              type="text"
              size="small"
              label="供給地点特定番号（２）"
              variant="outlined"
              required={false}
              id="supply_no_2"
              value={useSelector(
                (state) => state.preConsiderPointInfo.supply_no_2
              )}
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector(
                (state) => state.preConsiderPointInfo.error.supply_no_2
              )}
              helperText={useSelector(
                (state) => state.preConsiderPointInfo.message.supply_no_2
              )}
            />
          </Grid>
          <Grid item>
            <Typography sx={{ pt: 2, fontSize: 11 }}>
              複数地点ある場合は（２）以降も入力してください。
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={0}>
          <Grid item md={3}>
            <TextField
              fullWidth
              type="text"
              size="small"
              label="供給地点特定番号（３）"
              variant="outlined"
              required={false}
              id="supply_no_3"
              value={useSelector(
                (state) => state.preConsiderPointInfo.supply_no_3
              )}
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector(
                (state) => state.preConsiderPointInfo.error.supply_no_3
              )}
              helperText={useSelector(
                (state) => state.preConsiderPointInfo.message.supply_no_3
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default React.memo(PreConsiderPointInfo);

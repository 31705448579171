import React, { useEffect } from "react";
import {
  Grid,
  MenuItem,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";

import { replaceNumStr } from "../../../components/util";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../../../Store/Slice/keitou";

function Keitou(props) {
  const isEditeOk = useSelector(
    (state) => state.keitou[props.lineNo - 1].isEdit
  );
  //初期表示時
  useEffect(() => {
    //先頭行だけ入力可能とする
    if (props.lineNo === 1) {
      dispatch(setData({ no: props.lineNo - 1, data: { isEdit: true } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Storeから
  const dispatch = useDispatch();
  //Storeへ
  const changeValue = function (e) {
    //console.log(target);
    switch (e.target.id) {
      case "lowDelegation":
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: e.target.checked },
          })
        );
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: e.target.value },
          })
        );
    }
  };
  const changeSelect = function (e) {
    //console.log(e);
    switch (e.target.name) {
      case "keitou_destination":
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.name]: e.target.value },
          })
        );
        if (props.lineNo < 3) {
          if (e.target.value.length > 0) {
            dispatch(setData({ no: props.lineNo, data: { isEdit: true } }));
          } else {
            dispatch(setData({ no: props.lineNo, data: { isEdit: false } }));
          }
        }
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.name]: e.target.value },
          })
        );
    }
  };

  const blurField = function (e) {
    //console.log(target);
    switch (e.target.id) {
      case "special_after_cnt":
        if (props.lineNo < 3) {
          if (e.target.value.length > 0) {
            //口数が入力されていたら、
            dispatch(setData({ no: props.lineNo, data: { isEdit: true } }));
            dispatch(
              setData({
                no: props.lineNo - 1,
                data: { [e.target.id]: replaceNumStr(e.target.value) },
              })
            );
          } else {
            dispatch(setData({ no: props.lineNo, data: { isEdit: false } }));
          }
        }
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: replaceNumStr(e.target.value) },
          })
        );
    }
  };

  //console.log("Keitou");
  return (
    <Grid container spacing={2} sx={{ m: 2, mt: 0 }}>
      <span style={{ marginTop: "1.5em" }}>「{props.lineNo}」</span>
      <Grid item md={1.8}>
        <FormControl fullWidth size="small" disabled={!isEditeOk}>
          <InputLabel>発電設備の種別</InputLabel>
          <Select
            label="発電設備の種別"
            id="keitou_pgequip_type"
            name="keitou_pgequip_type"
            onChange={changeSelect}
            value={useSelector(
              (state) => state.keitou[props.lineNo - 1].keitou_pgequip_type
            )}
          >
            <MenuItem key={0} value="太陽光">
              太陽光
            </MenuItem>
            <MenuItem key={1} value="風力">
              風力
            </MenuItem>
            <MenuItem key={2} value="バイオマス">
              バイオマス
            </MenuItem>
            <MenuItem key={3} value="バイオガス">
              バイオガス
            </MenuItem>
            <MenuItem key={4} value="発電機（都市ガス）">
              発電機（都市ガス）
            </MenuItem>
            <MenuItem key={5} value="発電機（軽油）">
              発電機（軽油）
            </MenuItem>
            <MenuItem key={6} value="発電機（ＬＰＧ）">
              発電機（ＬＰＧ）
            </MenuItem>
            <MenuItem key={7} value="発電機（灯油）">
              発電機（灯油）
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={1.4}>
        <TextField
          fullWidth
          type="number"
          size="small"
          label="PCS容量"
          variant="outlined"
          required={false}
          disabled={!isEditeOk}
          InputProps={{
            endAdornment: <InputAdornment position="end">kW</InputAdornment>,
            inputProps: { min: 1 },
          }}
          id="keitou_pcs_capacity"
          value={useSelector(
            (state) => state.keitou[props.lineNo - 1].keitou_pcs_capacity
          )}
          onChange={changeValue}
          onBlur={blurField}
        />
      </Grid>
      <Grid item md={1.7}>
        <TextField
          fullWidth
          type="number"
          size="small"
          label="発電設備容量"
          variant="outlined"
          required={false}
          disabled={!isEditeOk}
          InputProps={{
            endAdornment: <InputAdornment position="end">kW</InputAdornment>,
            inputProps: { min: 1 },
          }}
          id="keitou_pgequip_capacity"
          value={useSelector(
            (state) => state.keitou[props.lineNo - 1].keitou_pgequip_capacity
          )}
          onChange={changeValue}
          onBlur={blurField}
        />
      </Grid>
      <Grid item md={1.8}>
        <TextField
          fullWidth
          type="number"
          size="small"
          label="モジュール容量"
          variant="outlined"
          required={false}
          disabled={!isEditeOk}
          InputProps={{
            endAdornment: <InputAdornment position="end">kW</InputAdornment>,
            inputProps: { min: 1 },
          }}
          id="keitou_module_capacity"
          value={useSelector(
            (state) => state.keitou[props.lineNo - 1].keitou_module_capacity
          )}
          onChange={changeValue}
          onBlur={blurField}
        />
      </Grid>
      <Grid item md={1.4}>
        <FormControl fullWidth size="small" disabled={!isEditeOk}>
          <InputLabel>逆潮流の有無</InputLabel>
          <Select
            label="逆潮流の有無"
            id="keitou_reverse_flow"
            name="keitou_reverse_flow"
            onChange={changeSelect}
            value={useSelector(
              (state) => state.keitou[props.lineNo - 1].keitou_reverse_flow
            )}
          >
            <MenuItem key={0} value=""></MenuItem>
            <MenuItem key={1} value="有">
              有
            </MenuItem>
            <MenuItem key={2} value="無">
              無
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={1.5}>
        <FormControl fullWidth size="small" disabled={!isEditeOk}>
          <InputLabel>押上効果の有無</InputLabel>
          <Select
            label="押上効果の有無"
            id="keitou_pushup_effect"
            name="keitou_pushup_effect"
            onChange={changeSelect}
            value={useSelector(
              (state) => state.keitou[props.lineNo - 1].keitou_pushup_effect
            )}
          >
            <MenuItem key={0} value=""></MenuItem>
            <MenuItem key={1} value="有">
              有
            </MenuItem>
            <MenuItem key={2} value="無">
              無
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={1.5}>
        <FormControl fullWidth size="small" disabled={!isEditeOk}>
          <InputLabel>売電先（FIT/FIT以外）</InputLabel>
          <Select
            label="売電先（FIT/FIT以外）"
            id="keitou_destination"
            name="keitou_destination"
            onChange={changeSelect}
            value={useSelector(
              (state) => state.keitou[props.lineNo - 1].keitou_destination
            )}
          >
            <MenuItem key={0} value=""></MenuItem>
            <MenuItem key={1} value="FIT">
              FIT
            </MenuItem>
            <MenuItem key={2} value="FIT以外">
              FIT以外
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default React.memo(Keitou);

import React, { useEffect } from "react";
import {
  Grid,
  MenuItem,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";

import { replaceNumStr } from "../../../components/util";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../../../Store/Slice/extraHighReserve";

function ExtraHighReserve(props) {
  const isEditeOk = useSelector(
    (state) => state.exhighReserve[props.lineNo - 1].isEdit
  );
  //初期表示時
  useEffect(() => {
    //先頭行だけ入力可能とする
    if (props.lineNo === 1) {
      dispatch(setData({ no: props.lineNo - 1, data: { isEdit: true } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Storeから
  const dispatch = useDispatch();
  //Storeへ
  const changeValue = function (e) {
    //console.log(target);
    switch (e.target.id) {
      case "lowDelegation":
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: e.target.checked },
          })
        );
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: e.target.value },
          })
        );
    }
  };
  const changeSelect = function (e) {
    //console.log(e);
    switch (e.target.name) {
      case "lowDelegation":
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.name]: e.target.checked },
          })
        );
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.name]: e.target.value },
          })
        );
    }
  };

  const blurField = function (e) {
    //console.log(target);
    switch (e.target.id) {
      case "high_alway_cnt":
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: replaceNumStr(e.target.value) },
          })
        );
    }
  };

  return (
    <Grid container spacing={2} sx={{ m: 2, mt: 0 }}>
      <Grid item md={2}>
        <FormControl fullWidth size="small" disabled={!isEditeOk}>
          <InputLabel>予備送電サービス</InputLabel>
          <Select
            label="予備送電サービス"
            id="sphigh_reserve_service"
            name="sphigh_reserve_service"
            onChange={changeSelect}
            value={useSelector(
              (state) =>
                state.exhighReserve[props.lineNo - 1].sphigh_reserve_service
            )}
          >
            <MenuItem key={0} value=""></MenuItem>
            <MenuItem key={1} value="予備送電サービスA">
              予備送電サービスA
            </MenuItem>
            <MenuItem key={2} value="予備送電サービスB">
              予備送電サービスB
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2}>
        <TextField
          fullWidth
          type="text"
          size="small"
          label="契約電力"
          variant="outlined"
          required={false}
          disabled={!isEditeOk}
          InputProps={{
            endAdornment: <InputAdornment position="end">kW</InputAdornment>,
          }}
          id="sphigh_reserve_contract_ep"
          value={useSelector(
            (state) =>
              state.exhighReserve[props.lineNo - 1].sphigh_reserve_contract_ep
          )}
          onChange={changeValue}
          onBlur={blurField}
        />
      </Grid>
      <Grid item md={2}>
        <FormControl fullWidth size="small" disabled={!isEditeOk}>
          <InputLabel>供給電圧</InputLabel>
          <Select
            label="供給電圧"
            endAdornment={
              <InputAdornment position="end" sx={{ mr: 2 }}>
                kV
              </InputAdornment>
            }
            id="sphigh_reserve_supply"
            name="sphigh_reserve_supply"
            onChange={changeSelect}
            value={useSelector(
              (state) =>
                state.exhighReserve[props.lineNo - 1].sphigh_reserve_supply
            )}
          >
            <MenuItem key={0} value=""></MenuItem>
            <MenuItem key={1} value="187">
              187
            </MenuItem>
            <MenuItem key={2} value="66">
              66
            </MenuItem>
            <MenuItem key={3} value="33">
              33
            </MenuItem>
            <MenuItem key={4} value="6">
              6
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2}>
        <FormControl fullWidth size="small" disabled={!isEditeOk}>
          <InputLabel>計量電圧</InputLabel>
          <Select
            label="計量電圧"
            endAdornment={
              <InputAdornment position="end" sx={{ mr: 2 }}>
                kV
              </InputAdornment>
            }
            id="sphigh_reserve_measure"
            name="sphigh_reserve_measure"
            onChange={changeSelect}
            value={useSelector(
              (state) =>
                state.exhighReserve[props.lineNo - 1].sphigh_reserve_measure
            )}
          >
            <MenuItem key={0} value=""></MenuItem>
            <MenuItem key={2} value="66">
              66
            </MenuItem>
            <MenuItem key={4} value="6">
              6
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default React.memo(ExtraHighReserve);

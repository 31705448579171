import { createSlice } from "@reduxjs/toolkit";

//state の初期値
const initialState = {
  electricity: false,
  apply_equip_gas: false,
  equip_lpg_bulk: false,
  equip_lpg_cylinder: false,
  equip_light_oil: false,
  equip_kerosene: false,
  equip_heavy_oil: false,
  equip_etc: false,
  equip_pending: false,
  total_floor_area: "",
  error: {
    electricity: false,
    apply_equip_gas: false,
    equip_lpg_bulk: false,
    equip_lpg_cylinder: false,
    equip_light_oil: false,
    equip_kerosene: false,
    equip_heavy_oil: false,
    equip_etc: false,
    equip_pending: false,
    total_floor_area: false,
  },
  message: {
    electricity: "",
    apply_equip_gas: "",
    equip_lpg_bulk: "",
    equip_lpg_cylinder: "",
    equip_light_oil: "",
    equip_kerosene: "",
    equip_heavy_oil: "",
    equip_etc: "",
    equip_pending: "",
    total_floor_area: "",
  },
};

//Sliceを生成する
const slice = createSlice({
  name: "etcInfo",
  initialState,
  reducers: {
    setData: (state, action) => {
      return Object.assign({}, state, action.payload);
    },
    setError: (state, action) => {
      state.error = Object.assign({}, state.error, action.payload);
      return state;
    },
    setMessage: (state, action) => {
      state.message = Object.assign({}, state.message, action.payload);
      return state;
    },
    clearData: () => {
      return initialState;
    },
  },
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const { setData, setError, setMessage, clearData } = slice.actions;

import React from "react";
import { Typography, Button, Box, Card, CardContent } from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { useNavigate } from "react-router-dom";

import { CircularProgress, Backdrop } from "@mui/material";

//UUID
import { v4 as uuidv4 } from "uuid";

//Redux
import { useDispatch } from "react-redux";
import { setData } from "../Store/Slice/companyInfoSlice";
import { useState, useEffect } from "react";

import { API, graphqlOperation, Auth } from "aws-amplify";
import { listUserMailCompanies } from "../graphql/queries";
import { Logout } from "@mui/icons-material";

function ApplicationEntrance(props) {
  const navigate = useNavigate();
  //Storeから
  const dispatch = useDispatch();

  //処理中
  const [progress, setProgress] = useState(true);

  //初期表示時
  useEffect(() => {
    //ログインユーザの最新申込から会社情報を取得
    const init = async () => {
      const currentUser = await Auth.currentAuthenticatedUser();
      dispatch(setData({ mailAddress: currentUser.attributes.email }));

      const currentUserInfo = await API.graphql(
        graphqlOperation(listUserMailCompanies, {
          mail: currentUser.attributes.email,
          sortDirection: "DESC",
          limit: 1,
        })
      );
      //console.log(currentUserInfo);
      if (currentUserInfo.data.listUserMailCompanies.items.length > 0) {
        const company_info =
          currentUserInfo.data.listUserMailCompanies.items[0];
        dispatch(setData({ company_name: company_info.company_name }));
        dispatch(setData({ postCd: company_info.postCd }));
        dispatch(setData({ address: company_info.address }));
        dispatch(setData({ tel: company_info.tel }));
        dispatch(
          setData({ PersonInChargeName: company_info.personInChargeName })
        );
        dispatch(setData({ mobilePhone: company_info.mobilePhone }));
      }
      const key_uuidv4 = uuidv4();
      dispatch(setData({ key_uuid: key_uuidv4 }));

      setProgress(false);
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function btnClick(e) {
    //console.log(e);
    if (e.target.id === "btn_kouri") {
      dispatch(setData({ company_type: "kouri" }));
      props.logger.info(props.user.attributes.email, "小売事業者ボタン押下");
    } else {
      dispatch(setData({ company_type: "kouji" }));
      props.logger.info(props.user.attributes.email, "その他事業者ボタン押下");
    }

    navigate("/entory");
  }

  async function logoout() {
    //props.logger.info(props.user.attributes.email, "ログアウト");
    navigate("/");
    await props.signOut();
  }

  return (
    <React.Fragment>
      <Backdrop open={progress}>
        <CircularProgress color="secondary" size="8rem" />
      </Backdrop>

      <Card variant="outlined" sx={{ mt: "60px", mx: "60px" }}>
        <CardContent>
          <Box textAlign="center">
            <Typography>小売電気事業者さまのお申込み</Typography>
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowCircleRightOutlinedIcon />}
              id="btn_kouri"
              onClick={btnClick}
            >
              小売電気事業者さま
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ mt: "30px", mx: "60px" }}>
        <CardContent>
          <Box textAlign="center">
            <Typography>電気工事会社さま、設計会社さま等</Typography>
            <Typography>（小売電気事業者さま以外のお申込み）</Typography>
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowCircleRightOutlinedIcon />}
              id="btn_kouji"
              onClick={btnClick}
            >
              電気工事会社さま、設計会社さま等
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ mt: "30px", mx: "60px" }}>
        <CardContent>
          <Box textAlign="center">
            <Button
              variant="outlined"
              size="large"
              endIcon={<Logout />}
              id="btn_logout"
              onClick={logoout}
            >
              終了
            </Button>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
export default ApplicationEntrance;

import {createSlice} from "@reduxjs/toolkit";

//state の初期値
const lowPowerInfo = {
    power_supply_vlt :"",
    power_kaihei :"",
    power_kyk : "",
    power_fuka : "",
    power_cnt : "",
    isEdit : false,
    error : {
        power_supply_vlt :false,
        power_kaihei : false,
        power_kyk : false,
        power_fuka : false,
        power_cnt: false,
    },
    message : {
        power_supply_vlt :"",
        power_kaihei : "",
        power_kyk : "",
        power_fuka : "",
        power_cnt : "",
    }
}
const initialState = 
    [lowPowerInfo,lowPowerInfo,lowPowerInfo]


//Sliceを生成する
const slice = createSlice({
    name : "lowPower"
    ,initialState
    ,reducers : {
        setData :(state, action) => {
            //console.log(action);
            state[action.payload.no] = Object.assign({}, state[action.payload.no], action.payload.data);
            return state;
        }
        , setError:(state, action) => {
            state.error =  Object.assign({}, state.error, action.payload);
            return state;
        }
        , setMessage:(state, action) => {
            state.message =  Object.assign({}, state.message, action.payload);
            return state;
        }
        , clearData :() => {
            return initialState;
        }

    }
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const {setData, setError, setMessage, clearData} = slice.actions;

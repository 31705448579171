import { createSlice } from "@reduxjs/toolkit";

//state の初期値
const keitouInfo = {
  keitou_pgequip_type: "",
  keitou_pcs_capacity: "",
  keitou_pgequip_capacity: "",
  keitou_module_capacity: "",
  keitou_reverse_flow: "",
  keitou_pushup_effect: "",
  keitou_destination: "",
  isEdit: false,
  error: {
    keitou_pgequip_type: false,
    keitou_pcs_capacity: false,
    keitou_pgequip_capacity: false,
    keitou_module_capacity: false,
    keitou_reverse_flow: false,
    keitou_pushup_effect: false,
    keitou_destination: false,
  },
  message: {
    keitou_pgequip_type: "",
    keitou_pcs_capacity: "",
    keitou_pgequip_capacity: "",
    keitou_module_capacity: "",
    keitou_reverse_flow: "",
    keitou_pushup_effect: "",
    keitou_destination: "",
  },
};
const initialState = [keitouInfo, keitouInfo, keitouInfo];

//Sliceを生成する
const slice = createSlice({
  name: "keitou",
  initialState,
  reducers: {
    setData: (state, action) => {
      //console.log(action);
      state[action.payload.no] = Object.assign(
        {},
        state[action.payload.no],
        action.payload.data
      );
      return state;
    },
    setError: (state, action) => {
      state.error = Object.assign({}, state.error, action.payload);
      return state;
    },
    setMessage: (state, action) => {
      state.message = Object.assign({}, state.message, action.payload);
      return state;
    },
    clearData: () => {
      return initialState;
    },
  },
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const { setData, setError, setMessage, clearData } = slice.actions;

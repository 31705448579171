import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  Select,
  InputLabel,
  Alert,
  Collapse,
} from "@mui/material";

import DesiredContractVlt from "./DesiredContracts/DesiredContractVlt";
import LowLight from "./DesiredContracts/LowLight";
import LowPower from "./DesiredContracts/LowPower";
import HighAlways from "./DesiredContracts/HighAlways";
import HighReserve from "./DesiredContracts/HighReserve";
import ExtraHighAlways from "./DesiredContracts/ExtraHighAlways";
import ExtraHighReserve from "./DesiredContracts/ExtraHighReserve";
import SpecialDevice from "./DesiredContracts/SpecialDevice";
import Keitou from "./DesiredContracts/Keitou";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../../Store/Slice/desiredContractInfo";
import { setTypeVisible } from "../../Store/Slice/desiredContractType";

//定数
import {
  entory_type,
  construction_type,
  demarcationPoint_list_low,
  demarcationPoint_list_high,
} from "./EntryFormConst";

function DesiredContractDetails(props) {
  const desiredContractInfo = useSelector((state) => state.desiredContractInfo);
  const lowvisible = useSelector(
    (state) => state.desiredContractType.lowVisible
  );
  const highvisible = useSelector(
    (state) => state.desiredContractType.highVisible
  );
  const sphighvisible = useSelector(
    (state) => state.desiredContractType.sphighVisible
  );
  const specialDevicevisible = useSelector(
    (state) => state.desiredContractType.specialDeviceVisible
  );
  const keitouvisible = useSelector(
    (state) => state.desiredContractType.keitouVisible
  );
  const desiredContractType = useSelector((state) => state.desiredContractType);

  //初期表示時
  useEffect(() => {
    //console.log("init");
  }, []);

  //Storeから
  const dispatch = useDispatch();
  const changeSelect = function (e) {
    //console.log(e);
    switch (e.target.name) {
      case "lowDelegation":
        dispatch(setData({ [e.target.id]: e.target.checked }));
        break;
      case "special_device":
        dispatch(setData({ [e.target.name]: e.target.value }));
        dispatch(
          setTypeVisible({
            type: "specialDevice",
            visible: e.target.value === "有",
          })
        );
        break;
      case "keitou":
        dispatch(setData({ [e.target.name]: e.target.value }));
        dispatch(
          setTypeVisible({
            type: "keitou",
            visible: e.target.value.charAt(0) === "有",
          })
        );
        break;
      default:
        dispatch(setData({ [e.target.name]: e.target.value }));
    }
  };

  return (
    <Card sx={{ mt: 1 }}>
      <CardHeader title="３．お申込み希望契約内容"></CardHeader>
      <Collapse in={desiredContractType.error.allItems}>
        <Alert severity="error">{desiredContractType.message.allItems}</Alert>
      </Collapse>
      <CardContent>
        {/*申込　低圧*/}
        <DesiredContractVlt
          entory_type={entory_type}
          construction_type={construction_type}
          demarcationPoint_list={demarcationPoint_list_low}
          type="low"
          typeJp="低圧"
          bgcolor="#eeeeee"
          mb={2}
        />
        {/*申込　高圧*/}
        <DesiredContractVlt
          entory_type={entory_type}
          construction_type={construction_type}
          demarcationPoint_list={demarcationPoint_list_high}
          type="high"
          typeJp="高圧"
          //bgcolor = "#eeeeee"
          mb={2}
        />

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item md={2}>
            <FormControl fullWidth size="small" disabled={false} value={""}>
              <InputLabel>特殊機器・非常用発電機</InputLabel>
              <Select
                label="特殊機器・非常用発電機"
                id="special_device"
                name="special_device"
                onChange={changeSelect}
                value={desiredContractInfo.special_device}
              >
                <MenuItem key={0} value=""></MenuItem>
                <MenuItem key={1} value="有">
                  有
                </MenuItem>
                <MenuItem key={2} value="無">
                  無
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2}>
            <FormControl fullWidth size="small" disabled={false} value={""}>
              <InputLabel>系統連系</InputLabel>
              <Select
                label="特殊機器・非常用発電機"
                id="keitou"
                name="keitou"
                onChange={changeSelect}
                value={desiredContractInfo.keitou}
              >
                <MenuItem key={0} value=""></MenuItem>
                <MenuItem key={1} value="無">
                  無
                </MenuItem>
                <MenuItem key={2} value="有">
                  有
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/*低圧選択時*/}
        <div style={{ display: lowvisible ? "block" : "none" }}>
          <Typography sx={{ fontWeight: "bold" }}>《低圧》</Typography>
          <Typography>
            a.電灯（１契約の場合は「１」を、複数契約の場合や集合住宅で部屋ごとにパターンが異なる場合は「２」以降も入力してください
          </Typography>
          <Typography>
            ※融雪用単独でご使用中の地点におけるお申込みについては、「供給電気方式・供給電圧」および「契約容量」へご入力願います。
          </Typography>
          <LowLight lineNo={1} />
          <LowLight lineNo={2} />
          <LowLight lineNo={3} />
          <LowLight lineNo={4} />
          <LowLight lineNo={5} />
          <LowLight lineNo={6} />
          <Typography sx={{ ml: 4 }}>
            ＊定額電灯：400VA以下の負荷設備の合計容量。契約電流：5A・10A・20A・30A・40A・50A・60A。契約容量：6kVA～49kVA(主開閉器容量(A)×200V×1/1,000)
          </Typography>

          <Typography sx={{ mt: 4 }}>
            b.動力（１契約の場合は「１」を、複数契約の場合は「２」以降も入力してください
          </Typography>
          <LowPower lineNo={1} />
          <LowPower lineNo={2} />
          <LowPower lineNo={3} />
        </div>

        {/*高圧選択時*/}
        <div style={{ display: highvisible ? "block" : "none" }}>
          <Typography sx={{ fontWeight: "bold", mt: 4 }}>《高圧》</Typography>
          <Typography>
            a.常時（１契約の場合は「１」を、複数契約の場合は「２」以降も入力してください
          </Typography>
          <HighAlways lineNo={1} />
          <HighAlways lineNo={2} />
          <HighAlways lineNo={3} />

          <Typography sx={{ mt: 1 }}>b.予備</Typography>
          <HighReserve lineNo={1} />
        </div>

        {/*特高選択時*/}
        <div style={{ display: sphighvisible ? "block" : "none" }}>
          <Typography sx={{ fontWeight: "bold", mt: 4 }}>
            《特別高圧》
          </Typography>
          <Typography>a.常時</Typography>
          <ExtraHighAlways lineNo={1} />

          <Typography sx={{ mt: 1 }}>b.予備</Typography>
          <ExtraHighReserve lineNo={1} />
        </div>

        {/*特殊機器選択時*/}
        <div style={{ display: specialDevicevisible ? "block" : "none" }}>
          <Typography sx={{ fontWeight: "bold", mt: 4 }}>
            《レントゲン・電気溶接機・非常用発電機・その他特殊機器》
          </Typography>
          <SpecialDevice lineNo={1} />
          <SpecialDevice lineNo={2} />
          <SpecialDevice lineNo={3} />
        </div>

        {/*系統連系有選択時*/}
        <div style={{ display: keitouvisible ? "block" : "none" }}>
          <Typography sx={{ fontWeight: "bold", mt: 4 }}>
            《系統連系》
          </Typography>
          <Keitou lineNo={1} />
          <Keitou lineNo={2} />
          <Keitou lineNo={3} />
        </div>
      </CardContent>
    </Card>
  );
}
export default React.memo(DesiredContractDetails);

import { createSlice } from "@reduxjs/toolkit";

//state の初期値
const initialState = {
  lowVisible: false,
  highVisible: false,
  sphighVisible: false,
  specialDeviceVisible: false,
  keitouVisible: false,
  error: {
    allItems: false,
  },
  message: {
    allItems: "",
  },
};

//Sliceを生成する
const slice = createSlice({
  name: "typeVisible",
  initialState,
  reducers: {
    setTypeVisible: (state, action) => {
      var itemType = action.payload.type + "Visible";
      return Object.assign({}, state, { [itemType]: action.payload.visible });
    },
    clearData: () => {
      return initialState;
    },
    validate: (state) => {
      if (
        state.lowVisible === false &&
        state.highVisible === false &&
        state.sphighVisible === false &&
        state.specialDeviceVisible === false &&
        state.keitouVisible === false
      ) {
        state.error = Object.assign({}, state.error, { allItems: true });
        state.message = Object.assign({}, state.message, {
          allItems: "必ず１つは選択してください。",
        });
      } else {
        state.error = Object.assign({}, state.error, { allItems: false });
        state.message = Object.assign({}, state.message, {
          allItems: "",
        });
      }
      return state;
    },
  },
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const { setTypeVisible, clearData, validate } = slice.actions;

import React, { useEffect } from "react";
import {
  Grid,
  MenuItem,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import { replaceNumStr } from "../../../components/util";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../../../Store/Slice/extraHighAlways";

function ExtraHighAlways(props) {
  const isEditeOk = useSelector(
    (state) => state.exhighAlways[props.lineNo - 1].isEdit
  );
  //初期表示時
  useEffect(() => {
    //先頭行だけ入力可能とする
    if (props.lineNo === 1) {
      dispatch(setData({ no: props.lineNo - 1, data: { isEdit: true } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Storeから
  const dispatch = useDispatch();
  //Storeへ
  const changeValue = function (e) {
    //console.log(target);
    switch (e.target.id) {
      case "lowDelegation":
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: e.target.checked },
          })
        );
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: e.target.value },
          })
        );
    }
  };

  const changeSelect = function (e) {
    //console.log(e);
    switch (e.target.name) {
      case "lowDelegation":
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.name]: e.target.checked },
          })
        );
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.name]: e.target.value },
          })
        );
    }
  };

  const blurField = function (e) {
    //console.log(target);
    switch (e.target.id) {
      case "high_alway_cnt":
        if (props.lineNo < 3) {
          if (e.target.value.length > 0) {
            //口数が入力されていたら、
            dispatch(setData({ no: props.lineNo, data: { isEdit: true } }));
            dispatch(
              setData({
                no: props.lineNo - 1,
                data: { [e.target.id]: replaceNumStr(e.target.value) },
              })
            );
          } else {
            dispatch(setData({ no: props.lineNo, data: { isEdit: false } }));
          }
        }
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: replaceNumStr(e.target.value) },
          })
        );
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ m: 2, mt: 0 }}>
        <Grid item md={2}>
          <TextField
            fullWidth
            type="text"
            size="small"
            label="想定DM(契約容量)"
            variant="outlined"
            required={false}
            disabled={!isEditeOk}
            InputProps={{
              endAdornment: <InputAdornment position="end">kW</InputAdornment>,
            }}
            id="sphigh_alway_dm"
            value={useSelector(
              (state) => state.exhighAlways[props.lineNo - 1].sphigh_alway_dm
            )}
            onChange={changeValue}
            onBlur={blurField}
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            fullWidth
            type="text"
            size="small"
            label="内自家補相当分"
            variant="outlined"
            required={false}
            disabled={!isEditeOk}
            InputProps={{
              endAdornment: <InputAdornment position="end">kW</InputAdornment>,
            }}
            id="sphigh_alway_jikaho"
            value={useSelector(
              (state) =>
                state.exhighAlways[props.lineNo - 1].sphigh_alway_jikaho
            )}
            onChange={changeValue}
            onBlur={blurField}
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            fullWidth
            type="text"
            size="small"
            label="変圧器合計容量"
            variant="outlined"
            required={false}
            disabled={!isEditeOk}
            InputProps={{
              endAdornment: <InputAdornment position="end">kVA</InputAdornment>,
            }}
            id="sphigh_alway_trans_total"
            value={useSelector(
              (state) =>
                state.exhighAlways[props.lineNo - 1].sphigh_alway_trans_total
            )}
            onChange={changeValue}
            onBlur={blurField}
          />
        </Grid>
        <Grid item md={2}>
          <FormControl fullWidth size="small" disabled={!isEditeOk}>
            <InputLabel>供給電圧</InputLabel>
            <Select
              label="供給電圧"
              endAdornment={
                <InputAdornment position="end" sx={{ mr: 2 }}>
                  kV
                </InputAdornment>
              }
              id="sphigh_alway_supply"
              name="sphigh_alway_supply"
              onChange={changeSelect}
              value={useSelector(
                (state) =>
                  state.exhighAlways[props.lineNo - 1].sphigh_alway_supply
              )}
            >
              <MenuItem key={0} value=""></MenuItem>
              <MenuItem key={1} value="187">
                187
              </MenuItem>
              <MenuItem key={2} value="66">
                66
              </MenuItem>
              <MenuItem key={3} value="33">
                33
              </MenuItem>
              <MenuItem key={4} value="6">
                6
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl fullWidth size="small" disabled={!isEditeOk}>
            <InputLabel>計量電圧</InputLabel>
            <Select
              label="計量電圧"
              endAdornment={
                <InputAdornment position="end" sx={{ mr: 2 }}>
                  kV
                </InputAdornment>
              }
              id="sphigh_alway_measure"
              name="sphigh_alway_measure"
              onChange={changeSelect}
              value={useSelector(
                (state) =>
                  state.exhighAlways[props.lineNo - 1].sphigh_alway_measure
              )}
            >
              <MenuItem key={0} value=""></MenuItem>
              <MenuItem key={2} value="66">
                66
              </MenuItem>
              <MenuItem key={4} value="6">
                6
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={1}>
          <TextField
            fullWidth
            type="text"
            size="small"
            label="口数"
            variant="outlined"
            required={false}
            disabled={!isEditeOk}
            InputProps={{
              endAdornment: <InputAdornment position="end">口</InputAdornment>,
            }}
            id="sphigh_alway_cnt"
            value={useSelector(
              (state) => state.exhighAlways[props.lineNo - 1].sphigh_alway_cnt
            )}
            onChange={changeValue}
            onBlur={blurField}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ m: 2 }}></Grid>
    </React.Fragment>
  );
}

export default React.memo(ExtraHighAlways);

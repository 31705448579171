import {createSlice} from "@reduxjs/toolkit";

//state の初期値
const highAlwaysInfo = {
    high_alway_dm :"",
    high_alway_jikaho :"",
    high_alway_trans_total : "",
    high_alway_facility_power : "",
    high_alway_cnt : "",
    isEdit : false,
    error : {
        high_alway_dm :false,
        high_alway_jikaho :false,
        high_alway_trans_total : false,
        high_alway_facility_power : false,
        high_alway_cnt: false,
    },
    message : {
        high_alway_dm :"",
        high_alway_jikaho :"",
        high_alway_trans_total : "",
        high_alway_facility_power : "",
        high_alway_cnt : "",
    }
}
const initialState = 
    [highAlwaysInfo,highAlwaysInfo,highAlwaysInfo]


//Sliceを生成する
const slice = createSlice({
    name : "highAlways"
    ,initialState
    ,reducers : {
        setData :(state, action) => {
            //console.log(action);
            state[action.payload.no] = Object.assign({}, state[action.payload.no], action.payload.data);
            return state;
        }
        , setError:(state, action) => {
            state.error =  Object.assign({}, state.error, action.payload);
            return state;
        }
        , setMessage:(state, action) => {
            state.message =  Object.assign({}, state.message, action.payload);
            return state;
        }
        , clearData :() => {
            return initialState;
        }

    }
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const {setData, setError, setMessage, clearData} = slice.actions;

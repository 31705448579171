/* 申込内容 */
export const entory_type = [
  { id: 1, value: "", label: "", name: "" },
  { id: 2, value: "新設", label: "新設", name: "" },
  { id: 3, value: "設備変更", label: "設備変更", name: "" },
  { id: 4, value: "その他", label: "その他", name: "" },
];
/* 工事種別 */
export const construction_type = [
  { id: 1, vlt: "low", type: "新設", ConstructionType: "" },
  { id: 2, vlt: "low", type: "新設", ConstructionType: "集合住宅" },
  { id: 3, vlt: "low", type: "新設", ConstructionType: "基地局（携帯電話等）" },
  { id: 4, vlt: "low", type: "新設", ConstructionType: "常時" },
  { id: 5, vlt: "low", type: "新設", ConstructionType: "臨時" },
  { id: 6, vlt: "low", type: "設備変更", ConstructionType: "" },
  {
    id: 7,
    vlt: "low",
    type: "設備変更",
    ConstructionType: "高圧から低圧への変更",
  },
  { id: 8, vlt: "low", type: "設備変更", ConstructionType: "需要者設備変更" },
  { id: 9, vlt: "low", type: "設備変更", ConstructionType: "容量変更" },
  { id: 10, vlt: "low", type: "設備変更", ConstructionType: "供給地点変更" },
  { id: 11, vlt: "low", type: "設備変更", ConstructionType: "供給方法変更" },
  {
    id: 12,
    vlt: "low",
    type: "設備変更",
    ConstructionType: "供給地点および供給方法変更",
  },
  { id: 13, vlt: "low", type: "その他", ConstructionType: "" },
  {
    id: 14,
    vlt: "low",
    type: "その他",
    ConstructionType: "新規分譲地における配柱計画",
  },
  { id: 101, vlt: "high", type: "新設", ConstructionType: "常時" },
  { id: 102, vlt: "high", type: "新設", ConstructionType: "臨時" },
  {
    id: 103,
    vlt: "high",
    type: "新設",
    ConstructionType: "予備電力新設（予備送電サービスＡ）",
  },
  {
    id: 104,
    vlt: "high",
    type: "新設",
    ConstructionType: "予備電力新設（予備送電サービスＢ）",
  },
  {
    id: 1041,
    vlt: "high",
    type: "新設",
    ConstructionType: "常時＋予備電力新設（予備送電サービスＡ）",
  },
  {
    id: 1042,
    vlt: "high",
    type: "新設",
    ConstructionType: "常時＋予備電力新設（予備送電サービスＢ）",
  },
  {
    id: 105,
    vlt: "high",
    type: "設備変更",
    ConstructionType: "低圧から高圧への変更",
  },
  {
    id: 106,
    vlt: "high",
    type: "設備変更",
    ConstructionType: "需要者設備変更",
  },
  { id: 107, vlt: "high", type: "設備変更", ConstructionType: "容量変更" },
  { id: 108, vlt: "high", type: "設備変更", ConstructionType: "供給地点変更" },
  { id: 109, vlt: "high", type: "設備変更", ConstructionType: "供給方法変更" },
  {
    id: 110,
    vlt: "high",
    type: "設備変更",
    ConstructionType: "供給地点および供給方法変更",
  },
  {
    id: 111,
    vlt: "high",
    type: "その他",
    ConstructionType: "新規分譲地における配柱計画",
  },
  { id: 1001, vlt: "sphigh", type: "新設", ConstructionType: "常時" },
  { id: 1002, vlt: "sphigh", type: "新設", ConstructionType: "臨時" },
  {
    id: 1003,
    vlt: "sphigh",
    type: "新設",
    ConstructionType: "予備電力新設（予備送電サービスＡ）",
  },
  {
    id: 1004,
    vlt: "sphigh",
    type: "新設",
    ConstructionType: "予備電力新設（予備送電サービスＢ）",
  },
  { id: 1005, vlt: "sphigh", type: "設備変更", ConstructionType: "容量変更" },
  {
    id: 1006,
    vlt: "sphigh",
    type: "設備変更",
    ConstructionType: "需要者設備変更",
  },
  {
    id: 1007,
    vlt: "sphigh",
    type: "設備変更",
    ConstructionType: "供給地点変更",
  },
  {
    id: 1008,
    vlt: "sphigh",
    type: "設備変更",
    ConstructionType: "供給方法変更",
  },
  {
    id: 1009,
    vlt: "sphigh",
    type: "設備変更",
    ConstructionType: "供給地点および供給方法変更",
  },
];
//責任分界点
export const demarcationPoint_list_low = [
  { id: 1, value: "", label: "", name: "" },
  {
    id: 2,
    value: "需要者様の外壁等引込線接続点",
    label: "需要者様の外壁等引込線接続点",
  },
  {
    id: 3,
    value: "弊社の計量器負荷側接続点",
    label: "弊社の計量器負荷側接続点",
  },
  {
    id: 4,
    value: "弊社の端子台負荷側接続点",
    label: "弊社の端子台負荷側接続点",
  },
  {
    id: 5,
    value: "弊社の変圧器負荷側接続点",
    label: "弊社の変圧器負荷側接続点",
  },
  {
    id: 6,
    value: "変更なし",
    label: "変更なし",
  },
];
export const demarcationPoint_list_high = [
  { id: 1, value: "", label: "", name: "" },
  {
    id: 2,
    value: "需要者様の区分開閉器電源側接続点",
    label: "需要者様の区分開閉器電源側接続点",
  },
  {
    id: 3,
    value: "弊社の分岐開閉器負荷側接続点",
    label: "弊社の分岐開閉器負荷側接続点",
  },
  {
    id: 4,
    value: "需要者様の断路器電源側接続点",
    label: "需要者様の断路器電源側接続点",
  },
  {
    id: 5,
    value: "弊社ケーブルと需要者様ケーブルの接続点",
    label: "弊社ケーブルと需要者様ケーブルの接続点",
  },
  {
    id: 6,
    value: "変更なし",
    label: "変更なし",
  },
];
export const demarcationPoint_list_sphigh = [
  { id: 1, value: "", label: "", name: "" },
  {
    id: 2,
    value: "弊社架空引込線と需要者様断路器の接続点",
    label: "弊社架空引込線と需要者様断路器の接続点",
  },
  {
    id: 3,
    value: "弊社架空引込線と需要者様壁抜ブッシングの接続点",
    label: "弊社架空引込線と需要者様壁抜ブッシングの接続点",
  },
  {
    id: 4,
    value: "弊社地中引込リード線と需要者様断路器の接続点",
    label: "弊社地中引込リード線と需要者様断路器の接続点",
  },
  {
    id: 5,
    value: "弊社地中引込リード線と需要者様母線の接続点",
    label: "弊社地中引込リード線と需要者様母線の接続点",
  },
];

//事業所一覧
export const jig_list = [
  { id: 2100000, value: "道北統括支店", label: "道北統括支店" },
  {
    id: 2110000,
    value: "名寄ネットワークセンター",
    label: "名寄ネットワークセンター",
  },
  {
    id: 2120000,
    value: "羽幌ネットワークセンター",
    label: "羽幌ネットワークセンター",
  },
  {
    id: 2130000,
    value: "稚内ネットワークセンター",
    label: "稚内ネットワークセンター",
  },
  {
    id: 2140000,
    value: "留萌ネットワークセンター",
    label: "留萌ネットワークセンター",
  },
  {
    id: 2150000,
    value: "浜頓別ネットワークセンター",
    label: "浜頓別ネットワークセンター",
  },
  {
    id: 2160000,
    value: "深川ネットワークセンター",
    label: "深川ネットワークセンター",
  },
  {
    id: 2170000,
    value: "天塩ネットワークセンター",
    label: "天塩ネットワークセンター",
  },
  {
    id: 2180000,
    value: "富良野ネットワークセンター",
    label: "富良野ネットワークセンター",
  },
  { id: 2500000, value: "北見支店", label: "北見支店" },
  {
    id: 2520000,
    value: "紋別ネットワークセンター",
    label: "紋別ネットワークセンター",
  },
  {
    id: 2540000,
    value: "遠軽ネットワークセンター",
    label: "遠軽ネットワークセンター",
  },
  {
    id: 2560000,
    value: "網走ネットワークセンター",
    label: "網走ネットワークセンター",
  },
  {
    id: 2580000,
    value: "斜里ネットワークセンター",
    label: "斜里ネットワークセンター",
  },
  { id: 3100000, value: "道央統括支店", label: "道央統括支店" },
  {
    id: 3110000,
    value: "札幌北ネットワークセンター",
    label: "札幌北ネットワークセンター",
  },
  {
    id: 3130000,
    value: "札幌西ネットワークセンター",
    label: "札幌西ネットワークセンター",
  },
  {
    id: 3150000,
    value: "札幌東ネットワークセンター",
    label: "札幌東ネットワークセンター",
  },
  {
    id: 3170000,
    value: "札幌南ネットワークセンター",
    label: "札幌南ネットワークセンター",
  },
  {
    id: 3190000,
    value: "千歳ネットワークセンター",
    label: "千歳ネットワークセンター",
  },
  { id: 3300000, value: "岩見沢支店", label: "岩見沢支店" },
  {
    id: 3320000,
    value: "滝川ネットワークセンター",
    label: "滝川ネットワークセンター",
  },
  {
    id: 3340000,
    value: "栗山ネットワークセンター",
    label: "栗山ネットワークセンター",
  },
  { id: 3500000, value: "小樽支店", label: "小樽支店" },
  {
    id: 3520000,
    value: "余市ネットワークセンター",
    label: "余市ネットワークセンター",
  },
  {
    id: 3540000,
    value: "岩内ネットワークセンター",
    label: "岩内ネットワークセンター",
  },
  {
    id: 3541900,
    value: "寿都ネットワークセンター",
    label: "寿都ネットワークセンター",
  },
  {
    id: 3560000,
    value: "倶知安ネットワークセンター",
    label: "倶知安ネットワークセンター",
  },
  { id: 4100000, value: "釧路支店", label: "釧路支店" },
  {
    id: 4120000,
    value: "中標津ネットワークセンター",
    label: "中標津ネットワークセンター",
  },
  {
    id: 4140000,
    value: "弟子屈ネットワークセンター",
    label: "弟子屈ネットワークセンター",
  },
  {
    id: 4160000,
    value: "根室ネットワークセンター",
    label: "根室ネットワークセンター",
  },
  { id: 4300000, value: "道東統括支店", label: "道東統括支店" },
  {
    id: 4320000,
    value: "新得ネットワークセンター",
    label: "新得ネットワークセンター",
  },
  {
    id: 4340000,
    value: "池田ネットワークセンター",
    label: "池田ネットワークセンター",
  },
  {
    id: 4360000,
    value: "大樹ネットワークセンター",
    label: "大樹ネットワークセンター",
  },
  {
    id: 4380000,
    value: "足寄ネットワークセンター",
    label: "足寄ネットワークセンター",
  },
  { id: 5100000, value: "室蘭支店", label: "室蘭支店" },
  { id: 5300000, value: "道央南統括支店", label: "道央南統括支店" },
  {
    id: 5320000,
    value: "富川ネットワークセンター",
    label: "富川ネットワークセンター",
  },
  {
    id: 5321700,
    value: "日高ネットワークセンター",
    label: "日高ネットワークセンター",
  },
  {
    id: 5340000,
    value: "浦河ネットワークセンター",
    label: "浦河ネットワークセンター",
  },
  {
    id: 5350000,
    value: "静内ネットワークセンター",
    label: "静内ネットワークセンター",
  },
  { id: 6100000, value: "道南統括支店", label: "道南統括支店" },
  {
    id: 6130000,
    value: "八雲ネットワークセンター",
    label: "八雲ネットワークセンター",
  },
  {
    id: 6160000,
    value: "江差ネットワークセンター",
    label: "江差ネットワークセンター",
  },
  {
    id: 6180000,
    value: "福島ネットワークセンター",
    label: "福島ネットワークセンター",
  },
];

import React, { useState, useEffect } from "react";
import {
  Grid,
  MenuItem,
  FormControlLabel,
  FormControl,
  Checkbox,
  Select,
  InputLabel,
  FormHelperText,
} from "@mui/material";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../../../Store/Slice/desiredContractVltSlice";
import { setTypeVisible } from "../../../Store/Slice/desiredContractType";

function DesiredContractVlt(props) {
  /* 申込内容 */
  const entory_type = props.entory_type;

  /* 工事種別 */
  const construction_type = props.construction_type;

  const demarcationPoint_list = props.demarcationPoint_list;

  //申込内容変更
  const changeEntoryType = (e) => {
    //console.log(e);
    dispatch(
      setData({ type: props.type, data: { [e.target.name]: e.target.value } })
    );
    var constructionType = [];
    construction_type.forEach(function (val) {
      //console.log(val);
      if (val.vlt === props.type && val.type === e.target.value) {
        constructionType.push({
          id: val.id,
          value: val.ConstructionType,
          label: val.ConstructionType,
        });
      }
    });
    dispatch(setData({ type: props.type, data: { construction_type: "" } }));
    setConstructionTypeList(constructionType);
  };

  //工事種別リスト
  const [constructionTypeList, setConstructionTypeList] = useState([]);

  //選択工事種別
  const changeConstructionType = (e) => {
    dispatch(
      setData({ type: props.type, data: { [e.target.name]: e.target.value } })
    );
  };

  //責任分界点
  const changeDemarcationPoint = (e) => {
    dispatch(
      setData({ type: props.type, data: { [e.target.name]: e.target.value } })
    );
  };

  //低圧・高圧・特高チェックボックスチェック変更処理
  const onchangeVlt = (e) => {
    //console.log(e);
    dispatch(
      setData({ type: props.type, data: { [e.target.id]: e.target.checked } })
    );
    if (e.target.checked === false) {
      //選択をクリアする
      dispatch(setData({ type: props.type, data: { entory_contents: "" } }));
      dispatch(setData({ type: props.type, data: { construction_type: "" } }));
      setConstructionTypeList([]);
      dispatch(
        setData({ type: props.type, data: { responsibility_point: "" } })
      );
    }
    dispatch(setTypeVisible({ type: props.type, visible: e.target.checked }));
  };

  //初期表示時
  useEffect(() => {
    //console.log("init");
  }, []);

  //Storeから
  const dispatch = useDispatch();

  return (
    <Grid container spacing={2} sx={{ bgcolor: props.bgcolor, mb: props.mb }}>
      <Grid item md={1}>
        <FormControlLabel
          label={props.typeJp}
          value={props.type}
          control={
            <Checkbox
              id="entory_check"
              checked={useSelector(
                (state) =>
                  state.desiredContractVlt[props.type].entory_info.entory_check
              )}
              onChange={onchangeVlt}
            />
          }
        />
      </Grid>
      <Grid item md={2}>
        <FormControl
          fullWidth
          size="small"
          disabled={
            !useSelector(
              (state) =>
                state.desiredContractVlt[props.type].entory_info.entory_check
            )
          }
          error={useSelector(
            (state) =>
              state.desiredContractVlt[props.type].entory_info.error
                .entory_contents
          )}
        >
          <InputLabel>申込内容</InputLabel>
          <Select
            label="申込内容"
            id="entory_contents"
            name="entory_contents"
            onChange={changeEntoryType}
            value={useSelector(
              (state) =>
                state.desiredContractVlt[props.type].entory_info.entory_contents
            )}
          >
            {entory_type.map((item) => {
              return (
                <MenuItem key={item.id} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>
            {useSelector(
              (state) =>
                state.desiredContractVlt[props.type].entory_info.message
                  .entory_contents
            )}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item md={4}>
        <FormControl
          fullWidth
          size="small"
          disabled={
            !useSelector(
              (state) =>
                state.desiredContractVlt[props.type].entory_info.entory_check
            )
          }
        >
          <InputLabel>工事種別</InputLabel>
          <Select
            label="工事種別"
            id="construction_type"
            name="construction_type"
            value={useSelector(
              (state) =>
                state.desiredContractVlt[props.type].entory_info
                  .construction_type
            )}
            onChange={changeConstructionType}
          >
            {constructionTypeList.map((item) => {
              return (
                <MenuItem key={item.id} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={5}>
        <FormControl
          fullWidth
          size="small"
          disabled={
            !useSelector(
              (state) =>
                state.desiredContractVlt[props.type].entory_info.entory_check
            )
          }
        >
          <InputLabel>供給地点（責任分界点）</InputLabel>
          <Select
            label="供給地点（責任分界点）"
            id="responsibility_point"
            name="responsibility_point"
            value={useSelector(
              (state) =>
                state.desiredContractVlt[props.type].entory_info
                  .responsibility_point
            )}
            onChange={changeDemarcationPoint}
          >
            {demarcationPoint_list.map((item) => {
              return (
                <MenuItem key={item.id} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default React.memo(DesiredContractVlt);

import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../../Store/Slice/etcInfo";

import { replaceNumStr } from "../../components/util";

function EtcInfo(props) {
  //初期表示時
  useEffect(() => {
    //console.log("init");
  }, []);

  //Storeから
  const dispatch = useDispatch();
  //Storeへ
  const changeValue = function (e) {
    //console.log(target);
    switch (e.target.id) {
      case "total_floor_area":
        dispatch(setData({ [e.target.id]: e.target.value }));
        break;
      default:
        dispatch(setData({ [e.target.id]: e.target.checked }));
    }
  };

  const blurField = function (e) {
    //console.log(target);
    switch (e.target.id) {
      default:
        dispatch(setData({ [e.target.id]: replaceNumStr(e.target.value) }));
    }
  };

  //console.log("EtcInfo");
  return (
    <Card sx={{ mt: 1 }}>
      <CardHeader title="５．その他情報（任意）"></CardHeader>
      <CardContent>
        <Typography sx={{ fontWeight: "bold" }}>
          《主エネルギー源》（複数選択可、当該地点における情報があれば入力してください。）
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <FormControlLabel
              label="電気"
              value="electricity"
              control={
                <Checkbox
                  id="electricity"
                  checked={useSelector((state) => state.etcInfo.electricity)}
                  onChange={changeValue}
                />
              }
            />
          </Grid>
          <Grid item md={2}>
            <FormControlLabel
              label="都市ガス"
              value="apply_equip_gas"
              control={
                <Checkbox
                  id="apply_equip_gas"
                  checked={useSelector(
                    (state) => state.etcInfo.apply_equip_gas
                  )}
                  onChange={changeValue}
                />
              }
            />
          </Grid>
          <Grid item md={2}>
            <FormControlLabel
              label="ＬＰＧ"
              value="equip_lpg_cylinder"
              control={
                <Checkbox
                  id="equip_lpg_cylinder"
                  checked={useSelector(
                    (state) => state.etcInfo.equip_lpg_cylinder
                  )}
                  onChange={changeValue}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <FormControlLabel
              label="Ａ重油"
              value="equip_heavy_oil"
              control={
                <Checkbox
                  id="equip_heavy_oil"
                  checked={useSelector(
                    (state) => state.etcInfo.equip_heavy_oil
                  )}
                  onChange={changeValue}
                />
              }
            />
          </Grid>
          <Grid item md={2}>
            <FormControlLabel
              label="灯油"
              value="equip_kerosene"
              control={
                <Checkbox
                  id="equip_kerosene"
                  checked={useSelector((state) => state.etcInfo.equip_kerosene)}
                  onChange={changeValue}
                />
              }
            />
          </Grid>
          <Grid item md={2}>
            <FormControlLabel
              label="軽油"
              value="equip_light_oil"
              control={
                <Checkbox
                  id="equip_light_oil"
                  checked={useSelector(
                    (state) => state.etcInfo.equip_light_oil
                  )}
                  onChange={changeValue}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <FormControlLabel
              label="その他"
              value="equip_etc"
              control={
                <Checkbox
                  id="equip_etc"
                  checked={useSelector((state) => state.etcInfo.equip_etc)}
                  onChange={changeValue}
                />
              }
            />
          </Grid>
          <Grid item md={2}>
            <FormControlLabel
              label="未定"
              value="equip_pending"
              control={
                <Checkbox
                  id="equip_pending"
                  checked={useSelector((state) => state.etcInfo.equip_pending)}
                  onChange={changeValue}
                />
              }
            />
          </Grid>
        </Grid>
        <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
          《延床面積》（当該地点における情報があれば入力してください。）
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <TextField
              fullWidth
              type="text"
              size="small"
              label="延床面積"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    ｍ<sup>2</sup>
                  </InputAdornment>
                ),
              }}
              value={useSelector((state) => state.etcInfo.total_floor_area)}
              id="total_floor_area"
              onChange={changeValue}
              onBlur={blurField}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default React.memo(EtcInfo);

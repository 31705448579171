import {createSlice} from "@reduxjs/toolkit";

//state の初期値
const highReserveInfo = {
    high_reserve_service :"",
    high_reserve_contract_ep :"",
    high_reserve_trans_total : "",
    high_reserve_facility_power : "",
    isEdit : false,
    error : {
        high_reserve_service :false,
        high_reserve_contract_ep :false,
        high_reserve_trans_total : false,
        high_reserve_facility_power : false,
    },
    message : {
        high_reserve_service :"",
        high_reserve_contract_ep :"",
        high_reserve_trans_total : "",
        high_reserve_facility_power : "",
    }
}
const initialState = 
    [highReserveInfo]


//Sliceを生成する
const slice = createSlice({
    name : "highReserve"
    ,initialState
    ,reducers : {
        setData :(state, action) => {
            //console.log(action);
            state[action.payload.no] = Object.assign({}, state[action.payload.no], action.payload.data);
            return state;
        }
        , setError:(state, action) => {
            state.error =  Object.assign({}, state.error, action.payload);
            return state;
        }
        , setMessage:(state, action) => {
            state.message =  Object.assign({}, state.message, action.payload);
            return state;
        }
        , clearData :() => {
            return initialState;
        }

    }
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const {setData, setError, setMessage, clearData} = slice.actions;

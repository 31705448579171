/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEntryForm = /* GraphQL */ `
  query GetEntryForm($id: ID!) {
    getEntryForm(id: $id) {
      id
      mail
      form_data
      tmstmp
      createdAt
      updatedAt
    }
  }
`;
export const listEntryForms = /* GraphQL */ `
  query ListEntryForms(
    $filter: ModelEntryFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntryForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mail
        form_data
        tmstmp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserMailCompany = /* GraphQL */ `
  query GetUserMailCompany($mail: AWSEmail!, $tmstmp: AWSDateTime!) {
    getUserMailCompany(mail: $mail, tmstmp: $tmstmp) {
      mail
      tmstmp
      company_name
      postCd
      address
      tel
      personInChargeName
      mobilePhone
      createdAt
      updatedAt
    }
  }
`;
export const listUserMailCompanies = /* GraphQL */ `
  query ListUserMailCompanies(
    $mail: AWSEmail
    $tmstmp: ModelStringKeyConditionInput
    $filter: ModelUserMailCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserMailCompanies(
      mail: $mail
      tmstmp: $tmstmp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        mail
        tmstmp
        company_name
        postCd
        address
        tel
        personInChargeName
        mobilePhone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

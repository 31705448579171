import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Typography,
  Paper,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  Checkbox,
  Collapse,
  Alert,
} from "@mui/material";

//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  setData,
  setError,
  setMessage,
} from "../../Store/Slice/companyInfoSlice";

import { replaceNumStr } from "../../components/util";
import { store } from "../../Store/store";

function CompanyInfo(props) {
  //初期表示時
  useEffect(() => {
    //
    // const init = async () => {
    //   const currentUser = await Auth.currentAuthenticatedUser();
    //   //console.log(currentUser);
    //   dispatch(setData({ mailAddress: currentUser.attributes.email }));
    // };
    // init();
    const state = store.getState();
    if (state.company.company_type === "kouji") {
      setDelegationVisible(true);
      setHeaderTitle("１．工事・設計会社情報");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //委任ブロックの表示非表示
  const [delegationVisible, setDelegationVisible] = useState(false);

  //タイトル情報
  const [headerTitle, setHeaderTitle] = useState("１．小売電気事業者情報");

  //Storeから
  const dispatch = useDispatch();
  //Storeへ
  const changeValue = function (target) {
    //console.log(target);
    switch (target.id) {
      case "lowDelegation":
        dispatch(setData({ [target.id]: target.checked }));
        break;
      default:
        dispatch(setData({ [target.id]: target.value }));
    }
  };
  //カーソルが離れた
  const blurField = function (target) {
    //console.log(target);
    switch (target.id) {
      case "company_name":
        setErrorMessage(
          target.id,
          checkEntry(target, "会社名を入力してください。")
        );
        break;
      case "postCd":
        checkPostCd(target);
        break;
      case "address":
        setErrorMessage(
          target.id,
          checkEntry(target, "住所を入力してください。")
        );
        break;
      case "tel":
        setErrorMessage(
          target.id,
          checkEntry(target, "電話番号を入力してください。")
        );
        break;
      case "PersonInChargeName":
        setErrorMessage(
          target.id,
          checkEntry(target, "担当者名を入力してください。")
        );
        break;
      case "mailAddress":
        setErrorMessage(
          target.id,
          checkEntry(target, "メールアドレスを入力してください。")
        );
        break;
      case "mobilePhone":
        //setErrorMessage(target.id, checkEntry(target, "を入力してください。"));
        break;
      default:
      //dispatch(setData({[target.id]: target.value}));
    }
  };
  //必須入力チェック
  function checkEntry(target, err_msg) {
    if (target.value.length === 0) {
      return err_msg;
    }
    return "";
  }
  //郵便番号チェック
  function checkPostCd(target) {
    if (target.value.length === 0) {
      setErrorMessage(
        target.id,
        checkEntry(target, "郵便番号を入力してください。")
      );
      return target.value;
    }
    //ハイフン抜いて数字７桁
    var postcd = replaceNumStr(target.value).replace(/-－/g, "");
    const regex = /^\d{7}$/;
    if (!regex.test(postcd)) {
      setErrorMessage(target.id, "郵便番号は半角数字７桁で入力してください。");
      return target.value;
    }
    //エラーなし
    setErrorMessage(target.id, "");
    dispatch(setData({ [target.id]: replaceNumStr(target.value) }));
    return target.value;
  }
  //メッセージセット
  function setErrorMessage(id, message) {
    dispatch(setError({ [id]: message.length > 0 }));
    dispatch(setMessage({ [id]: message }));
  }

  //console.log("CompanyInfo");
  return (
    <Card sx={{ mt: 1 }}>
      <CardHeader title={headerTitle}></CardHeader>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <TextField
              fullWidth
              type="text"
              size="small"
              label="会社名"
              variant="outlined"
              inputProps={{ required: true }}
              required={true}
              value={useSelector((state) => state.company.company_name)}
              id="company_name"
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector((state) => state.company.error.company_name)}
              helperText={useSelector(
                (state) => state.company.message.company_name
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item md={2}>
            <TextField
              fullWidth
              type="text"
              size="small"
              label="郵便番号"
              variant="outlined"
              required={true}
              value={useSelector((state) => state.company.postCd)}
              id="postCd"
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector((state) => state.company.error.postCd)}
              helperText={useSelector((state) => state.company.message.postCd)}
            />
          </Grid>
          <Grid item md={10}>
            <TextField
              fullWidth
              type="text"
              size="small"
              label="住所"
              variant="outlined"
              required={true}
              value={useSelector((state) => state.company.address)}
              id="address"
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector((state) => state.company.error.address)}
              helperText={useSelector((state) => state.company.message.address)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item md={2}>
            <TextField
              fullWidth
              type="tel"
              size="small"
              label="電話番号"
              variant="outlined"
              required={true}
              value={useSelector((state) => state.company.tel)}
              id="tel"
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector((state) => state.company.error.tel)}
              helperText={useSelector((state) => state.company.message.tel)}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              fullWidth
              type="text"
              size="small"
              label="担当者名"
              variant="outlined"
              required={true}
              value={useSelector((state) => state.company.PersonInChargeName)}
              id="PersonInChargeName"
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector(
                (state) => state.company.error.PersonInChargeName
              )}
              helperText={useSelector(
                (state) => state.company.message.PersonInChargeName
              )}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              fullWidth
              type="tel"
              size="small"
              label="担当者携帯番号"
              variant="outlined"
              required={false}
              value={useSelector((state) => state.company.mobilePhone)}
              id="mobilePhone"
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector((state) => state.company.error.mobilePhone)}
              helperText={useSelector(
                (state) => state.company.message.mobilePhone
              )}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              fullWidth
              type="email"
              size="small"
              label="担当者メールアドレス"
              variant="outlined"
              inputProps={{ readOnly: true }}
              disabled
              required={true}
              value={useSelector((state) => state.company.mailAddress)}
              id="mailAddress"
              onChange={(e) => {
                changeValue(e.target);
              }}
              onBlur={(e) => {
                blurField(e.target);
              }}
              error={useSelector((state) => state.company.error.mailAddress)}
              helperText={useSelector(
                (state) => state.company.message.mailAddress
              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          mt={2}
          style={{ display: delegationVisible ? "block" : "none" }}
        >
          <Grid item md={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography sx={{ fontWeight: "bold" }}>
                  ＊低圧および高圧の設備変更における供給側接続事前検討申込みに係わる委任について
                </Typography>
                <Typography>
                  　設備変更における供給側接続事前検討申込みの場合、本来、小売電気事業者さまからのお申込みとなります。
                </Typography>
                <Typography>
                  　小売電気事業者さま以外からお申込みされる場合は、現在供給を行なっている小売電気事業者さまの承諾を得たうえでお申込みください。
                </Typography>
                <Typography>
                  　なお、「離島等供給」「電気最終保障供給」を適用しているお客さまにつきましては、小売電気事業者さまへの委任有無に関わらず、当該チェックボックスにチェックのうえお申込みください。
                </Typography>

                <Paper elevation={3} sx={{ pl: 2, mt: 2 }}>
                  <Collapse
                    in={useSelector(
                      (state) => state.company.error.lowDelegation
                    )}
                  >
                    <Alert severity="error">
                      {useSelector(
                        (state) => state.company.message.lowDelegation
                      )}
                    </Alert>
                  </Collapse>

                  <FormControlLabel
                    label="小売電気事業者さまから承諾のうえ申込みいたします。"
                    control={
                      <Checkbox
                        checked={useSelector(
                          (state) => state.company.lowDelegation
                        )}
                        id="lowDelegation"
                        onChange={(e) => {
                          changeValue(e.target);
                        }}
                      />
                    }
                  />
                </Paper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default React.memo(CompanyInfo);

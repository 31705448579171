import React, { useEffect } from "react";
import {
  Grid,
  MenuItem,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";

import { replaceNumStr } from "../../../components/util";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../../../Store/Slice/highReserve";

function HighReserve(props) {
  const isEditeOk = useSelector(
    (state) => state.highReserve[props.lineNo - 1].isEdit
  );
  //初期表示時
  useEffect(() => {
    //先頭行だけ入力可能とする
    if (props.lineNo === 1) {
      dispatch(setData({ no: props.lineNo - 1, data: { isEdit: true } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Storeから
  const dispatch = useDispatch();
  //Storeへ
  const changeValue = function (e) {
    //console.log(target);
    switch (e.target.id) {
      case "lowDelegation":
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: e.target.checked },
          })
        );
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: e.target.value },
          })
        );
        //変圧器合計容量だったら契約設備電力を計算してセットする
        if (e.target.id === "high_reserve_trans_total") {
          calc_facility_power(e.target.value);
        }
    }
  };
  const changeSelect = function (e) {
    //console.log(e);
    switch (e.target.name) {
      case "lowDelegation":
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.name]: e.target.checked },
          })
        );
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.name]: e.target.value },
          })
        );
    }
  };

  const blurField = function (e) {
    //console.log(target);
    switch (e.target.id) {
      case "high_alway_cnt":
        break;
      default:
        dispatch(
          setData({
            no: props.lineNo - 1,
            data: { [e.target.id]: replaceNumStr(e.target.value) },
          })
        );
    }
  };
  //契約設備電力計算
  const calc_facility_power = function (trans_total) {
    //ブランクはクリア
    if (trans_total.length === 0) {
      dispatch(
        setData({
          no: props.lineNo - 1,
          data: {
            high_reserve_facility_power: "",
          },
        })
      );
    }
    //計算
    let facility_power = 0;
    if (trans_total <= 50) {
      facility_power = trans_total * 0.8;
    } else if (trans_total <= 100) {
      facility_power = 50 * 0.8 + (trans_total - 50) * 0.7;
    } else if (trans_total <= 300) {
      facility_power = 50 * 0.8 + 50 * 0.7 + (trans_total - 100) * 0.6;
    } else if (trans_total <= 600) {
      facility_power =
        50 * 0.8 + 50 * 0.7 + 200 * 0.6 + (trans_total - 300) * 0.5;
    } else {
      facility_power =
        50 * 0.8 + 50 * 0.7 + 200 * 0.6 + 300 * 0.5 + (trans_total - 600) * 0.4;
    }
    dispatch(
      setData({
        no: props.lineNo - 1,
        data: {
          high_reserve_facility_power: Math.round(facility_power),
        },
      })
    );
  };

  return (
    <Grid container spacing={2} sx={{ m: 2, mt: 0 }}>
      <span style={{ marginTop: "1.5em", width: "2.5em" }}></span>
      <Grid item md={2}>
        <FormControl fullWidth size="small" disabled={!isEditeOk}>
          <InputLabel>予備送電サービス</InputLabel>
          <Select
            label="予備送電サービス"
            id="high_reserve_service"
            name="high_reserve_service"
            onChange={changeSelect}
            value={useSelector(
              (state) =>
                state.highReserve[props.lineNo - 1].high_reserve_service
            )}
          >
            <MenuItem key={0} value=""></MenuItem>
            <MenuItem key={1} value="予備送電サービスA">
              予備送電サービスA
            </MenuItem>
            <MenuItem key={2} value="予備送電サービスB">
              予備送電サービスB
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2}>
        <TextField
          fullWidth
          type="number"
          size="small"
          label="契約電力"
          variant="outlined"
          required={false}
          disabled={!isEditeOk}
          InputProps={{
            endAdornment: <InputAdornment position="end">kW</InputAdornment>,
            inputProps: { min: 1 },
          }}
          id="high_reserve_contract_ep"
          value={useSelector(
            (state) =>
              state.highReserve[props.lineNo - 1].high_reserve_contract_ep
          )}
          onChange={changeValue}
          onBlur={blurField}
        />
      </Grid>
      <Grid item md={2}>
        <TextField
          fullWidth
          type="number"
          size="small"
          label="変圧器合計容量"
          variant="outlined"
          required={false}
          disabled={!isEditeOk}
          InputProps={{
            endAdornment: <InputAdornment position="end">kVA</InputAdornment>,
            inputProps: { min: 1 },
          }}
          id="high_reserve_trans_total"
          value={useSelector(
            (state) =>
              state.highReserve[props.lineNo - 1].high_reserve_trans_total
          )}
          onChange={changeValue}
          onBlur={blurField}
        />
      </Grid>
      <Grid item md={2} sx={{ display: "none" }}>
        <TextField
          fullWidth
          type="number"
          size="small"
          label="契約設備電力"
          variant="outlined"
          required={false}
          disabled={true}
          InputProps={{
            endAdornment: <InputAdornment position="end">kW</InputAdornment>,
          }}
          id="high_reserve_facility_power"
          value={useSelector(
            (state) =>
              state.highReserve[props.lineNo - 1].high_reserve_facility_power
          )}
          //onChange={changeValue}
          //onBlur={blurField}
        />
      </Grid>
    </Grid>
  );
}

export default React.memo(HighReserve);

import { createSlice } from "@reduxjs/toolkit";

//state の初期値
const initialState = {
  company_name: "",
  postCd: "",
  address: "",
  tel: "",
  PersonInChargeName: "",
  mailAddress: "",
  mobilePhone: "",
  lowDelegation: false,
  company_type: "",
  key_uuid: "",
  error: {
    company_name: false,
    postCd: false,
    address: false,
    tel: false,
    PersonInChargeName: false,
    mailAddress: false,
    mobilePhone: false,
    lowDelegation: false,
    company_type: false,
  },
  message: {
    company_name: "",
    postCd: "",
    address: "",
    tel: "",
    PersonInChargeName: "",
    mailAddress: "",
    mobilePhone: "",
    lowDelegation: "",
    company_type: "",
  },
};

//Sliceを生成する
const slice = createSlice({
  name: "companyInfo",
  initialState,
  reducers: {
    setData: (state, action) => {
      //console.log(action);
      //return {...action.payload, ...state}
      return Object.assign({}, state, action.payload);
    },
    getData: (state) => {
      return state;
    },
    setError: (state, action) => {
      state.error = Object.assign({}, state.error, action.payload);
      return state;
    },
    setMessage: (state, action) => {
      state.message = Object.assign({}, state.message, action.payload);
      return state;
    },
    clearData: () => {
      return initialState;
    },
    validate: (state) => {
      if (state.company_name.length === 0) {
        state.error = Object.assign({}, state.error, { company_name: true });
        state.message = Object.assign({}, state.message, {
          company_name: "会社名を入力してください",
        });
      }
      if (state.postCd.length === 0) {
        state.error = Object.assign({}, state.error, { postCd: true });
        state.message = Object.assign({}, state.message, {
          postCd: "郵便番号を入力してください",
        });
      }
      if (state.address.length === 0) {
        state.error = Object.assign({}, state.error, {
          address: true,
        });
        state.message = Object.assign({}, state.message, {
          address: "住所を入力してください",
        });
      }
      if (state.tel.length === 0) {
        state.error = Object.assign({}, state.error, {
          tel: true,
        });
        state.message = Object.assign({}, state.message, {
          tel: "電話番号を入力してください",
        });
      }
      if (state.PersonInChargeName.length === 0) {
        state.error = Object.assign({}, state.error, {
          PersonInChargeName: true,
        });
        state.message = Object.assign({}, state.message, {
          PersonInChargeName: "担当者名を入力してください",
        });
      }

      state.error = Object.assign({}, state.error, {
        lowDelegation: false,
      });
      state.message = Object.assign({}, state.message, {
        lowDelegation: "",
      });

      return state;
    },
  },
});

//Reducerをエクスポートする
export default slice.reducer;

//Action Creatorsをエクスポートする
export const { setData, getData, setError, setMessage, clearData, validate } =
  slice.actions;
